import React, { useState, useContext } from "react"
import { StateContext } from '@/providers/StateProviders';
import TextareaAutosize from 'react-textarea-autosize';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useAutoSave } from '@/hooks/useAutoSave';

// import { TextDataWatch } from '@/text/TextDataWatch';

// import { useRecoilState, useSetRecoilState } from 'recoil'
// import { textDataAtom, textOpenAtom } from '@/providers/Atoms';
import { useSetRecoilState } from 'recoil'
import { saveStatusAtom } from '@/providers/Atoms';


export const Textarea = React.memo(({ part_id, text_type, obj, setObj, tableValue,
  placeholder, clickHandler }) => {
  
  // console.log("テキストレンダ", obj);
  const setSaveStatus = useSetRecoilState(saveStatusAtom)
  
  const { client } = useContext(StateContext);
  // const setClickText = useSetRecoilState(clickTextAtom)
  // const [ open, setOpen ] = useState(false);

  
  const InputChange = event => {
    const { name, value } = event.target;
    setObj((prev)=> ({ ...prev, part_id: part_id, text_type: text_type, [name]: value}));
  };
  
  
  // 保存
  const update = () => {
    client.patch(`/api/v1/texts/${obj.id}`, obj)
    .then(resp => {
      if(resp.data.id !== obj.id ) {
        setObj(resp.data);
        console.log("セット");
      }
      setSaveStatus(false);
      console.log("保存しました");
    })
    .catch(e => {
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(obj, update, 3000);

  
  
  // const [ textData, setTextData ] = useRecoilState(textDataAtom)
  // // const setTextData = useSetRecoilState(textDataAtom)
  // const setTextOpen = useSetRecoilState(textOpenAtom)
  
  // const textHandler = ()=> {
  //   setTextData({ ...obj, part_id: part_id, text_type: text_type});
  //   setOpen(true);
  //   setTextOpen(true);
  // };
  
  // const blurHandler = ()=> {
  //   // setOpen(false)
  // };
  
  // const value = tableValue || null
  
  return (
    <>
      <TextareaAutosize
        type="text"
        name="content"
        value={obj.content || tableValue || ""}
        onChange={InputChange}
        placeholder={placeholder}
        onClick={clickHandler}
        // onFocus={textHandler}
        // onBlur={blurHandler}
      />
    </>
  );
});

      // {`${textData.part_id}_${textData.text_type}` == `${part_id}_${text_type}` &&
      //   <TextDataWatch setObj={setObj}/>
      // }


// <span className="text_edit_box">
    
//   <TextEdit 
//     obj={obj} 
//     setObj={setObj}
//     open={open}
//     setOpen={setOpen}
//   />
  
//   <Tag tag={tag}>
//     <TextareaAutosize
//       type="text"
//       name="content"
//       value={obj.content || ""}
//       onChange={InputChange}
//       placeholder="テキスト"
//       onClick={textHandler}
//     />
//   </Tag>
// </span>


  // // refをtrueで初期化。
  // const Reff = useRef(true);
  
  // // useEffect(() => {
  // //   if (Reff.current) {
  // //     Reff.current = false;
  // //     return;
  // //   }
    
  // //   if(obj.id == clickText.id) {
  // //     setobj(clickText);
  // //     console.log("テキストセット");
  // //   }
  // // }, [!clickText.id]);