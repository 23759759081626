import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Sections } from '@/section/Sections';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";


export const FooterSections = React.memo(({ sections, setSections, SettingCSS}) => {
  
  const { productId } = useParams();
  
  return (
    <>
      <div className="wrapper">
        <div className="headline_field">
          <h2 className="page_label">フッター</h2>
          <Link to={`/product/${productId}/footer/edit`} className="page_label_btn">フッター編集へ</Link>
        </div>
      </div>

      {sections.length !== 0 
        ? <div className="page_area" css={SettingCSS}>
            <Sections sections={sections} setSections={setSections} sharing="footer"/>
          </div>
        : <div className="wrapper">
            <div className="page_setting_box" style={{padding:"1.5rem 3.5rem 1.5rem"}}>
              共通フッターはありません。
              <Link to={`/product/${productId}/footer/edit`} className="">作成する</Link>
            </div>
          </div>
      }
    </>
  );
  
});
