import React from "react"
import { useParams, Link } from 'react-router-dom';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import { outlineCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsLine } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

export const Sns_A = React.memo(({ part }) => {
  
  // console.log("SNSパーツレンダ");
  const { productId } = useParams();
  
  const snsCSS = css`
    ${outlineCSS(part)}
    
    .sns_btn {
      color: ${part.font_color};
      background: ${part.border_color}; 
    }
  `
  
  return (
    <>
      <Link to={`/product/${productId}/setting?key=Sns`}>
        <div className="menu_select_btn">各SNSのURLを設定</div>
      </Link>
      
      <div
        className={`sns_1 ${part.class_name} ${part.add_class || ""}`}
        css={snsCSS}
        id={part.add_id}
      >
        <a href="" className="line_btn sns_btn"><BsLine/></a>
        <a href="" className="twitter_btn sns_btn"><BsTwitter/></a>
        <a href="" className="facebook_btn sns_btn"><BsFacebook/></a>
        <a href="" className="instagram_btn sns_btn"><BsInstagram/></a>
        <a href="" className="youtube_btn sns_btn"><BsYoutube/></a>
        <a href="" className="tiktok_btn sns_btn"><FaTiktok/></a>
  
      </div>
    </>
  );
  
}); 

      // <a href="" className="sns_btn twitter"><BiLogoTwitter/></a>
      // <a href="" className="sns_btn facebook"><BiLogoFacebook/></a>
      // <a href="" className="sns_btn instagram"><BiLogoInstagramAlt/></a>
      // <a href="" className="sns_btn youtube"><BiLogoYoutube/></a>
      // <a href="" className="sns_btn tiktok"><BiLogoTiktok/></a>
      // <a href="" className="sns_btn line">LINE</a>


// <div class="share-box">
     
//     <!-- Twitter -->
//     <a href="javascript:window.open('http://twitter.com/share?text='+encodeURIComponent(document.title)+'&url='+encodeURIComponent(location.href),'sharewindow','width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=!');" class="sns_btn twitter"><i class="fab fa-twitter"></i></a>

//     <!-- Facebook -->
//     <span id="fb-root"></span>
//     <script async defer crossorigin="anonymous" src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v11.0&appId=202811158557333&autoLogAppEvents=1" nonce="ys0AWegd"></script>
//     <span class="" data-href="<%= request.url %>" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=<%= request.url %>%2F&amp;src=sdkpreparse" class="sns_btn facebook"><i class="fab fa-facebook-f"></i></a></span>

//     <!-- LINE -->
//     <a href="javascript:window.open('http://line.me/R/msg/text/?'+encodeURIComponent(document.title)+'%20'+encodeURIComponent(location.href),'sharewindow','width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=!');" class="sns_btn line">LINE</a>
    
// </div>