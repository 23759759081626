import React, { useState } from 'react';
// import { BiLaptop, BiTab, BiMobile } from "react-icons/bi";

export const WidthSwitch = React.memo(({ widthState }) => {
  
  // const { setWidth } = widthState
  
  // const WidthChange = () => {
  //   setWidth("360px")
  // };
  // const TabletWidthChange = () => {
  //   setWidth("820px")
  // };
  // const PcWidthChange = () => {
  //   setWidth("100%")
  // };
  
  return (
    <></>
  );
});

    // <div className="switching_btns">
    //   <div className="btns_box">
    //     <div className="item_btn" onClick={PcWidthChange}>
    //       <span><BiLaptop/>パソコン</span>
    //     </div>
    //     <div className="item_btn" onClick={TabletWidthChange}>
    //       <span><BiTab/>タブレット</span>
    //     </div>
    //     <div className="item_btn" onClick={WidthChange}>
    //       <span><BiMobile/>スマホ</span>
    //     </div>
    //   </div>
    // </div>