import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PartCategory } from '@/part/PartTypes';
import { PartSelect } from '@/part/PartSelect';
import { ShareBlocksSelect } from '@/section/ShareBlocksSelect';
import { BiPlus, BiX } from "react-icons/bi";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom } from '@/providers/Atoms';


export const NewSection = React.memo(({ page_id, sections, setSections, sharing, index, setOpen }) => {
  
  // console.log("新規セクションボタン", "レンダ");
  
  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  // const { sections, setSections } = sectionsState;
  // const { saveData, setSaveData } = useContext(SaveDataContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)
  const [ shareBlocks, setShareBlocks ] = useState([]);
  
  const new_section_date = {
    page_id: page_id,
    sharing: sharing,
    subdomain: productId,
  };
  const [ section, setSection ] = useState(new_section_date);
  // console.log("データ", section);
  
  // const PartTypeChange = event => {
  //   const { name, value } = event.target;
  //   let type = value.split(" ")
  //   setSection({ 
  //     ...section, 
  //     // [name]: value, 
  //     // order: Object.keys(sections).length,
  //     // order: order,
  //     component_name: type[0], 
  //     class_name: type[1]
  //   });
  // };
  
  const PartTypeChange = (value) => {
    let type = value.split(" ")
    setSection((prev)=> ({ ...prev, component_name: type[0], class_name: type[1]}));
  };
  
  // 共有ブロックを選択
  const SelectItem = event => {
    const { name, value } = event.target;
    setSection((prev)=> ({ ...prev, [name]: value }));
  };
  
  
  // // 順番を保存
  // const OrderUpdate = (new_objs) => {
  //   client.patch(`/api/v1/sections/order`, {sections: new_objs})
  //   .then(resp => {
  //     console.log("並び替え完了");
  //   })
  // };
  
  //並び順を保存
  const OrderUpdate = (new_objs, new_data) => {
    client.patch(`/api/v1/sections/order`, {sections: new_objs})
    .then(resp => {
      // setSaveData((prev)=> ({ 
      // ...prev, 
      //   sections: resp.data.new_sections,
      //   blocks: [...prev.blocks, new_data.block],
      //   parts: [...prev.parts, new_data.part]
      // }));
      console.log("並び替え完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };

  const AddSection = (path) => {
    // client.post('/api/v1/sections', section)
    client.post(`/api/v1/${path}`, section)
    .then(resp => {
      // const newObjs = [...sections, resp.data.section];
      const new_objs = [...sections];
      new_objs.splice(index+1, 0, resp.data.section)
      setSections(new_objs);
      // setToastObj({message: "ブロックを追加しました", color: "#D1E7DD"});
      
      // if (resp.data.block.sharing !== "sharing") {
      //   setSaveData((prev)=> ({ 
      //   ...prev, 
      //     sections: [...prev.sections, resp.data.section],
      //     blocks: [...prev.blocks, resp.data.block],
      //     parts: [...prev.parts, resp.data.part]
      //   }));
      // } else {
      //   setSaveData((prev)=> ({ 
      //   ...prev, 
      //     sections: [...prev.sections, resp.data.section],
      //     blocks: [...prev.blocks, resp.data.block],
      //   }));
      // }
      
      // 順番を保存
      OrderUpdate(new_objs, resp.data);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    })
  };
  
  
  const [ show, setShow ] = useState("Part");
  
  const GetShareBlocks = () => {
    // setShow((prev)=> !prev);
    setShow("Block")
    
    if(shareBlocks == ""){
      client.get(`/api/v1/blocks/sharing?subdomain=${productId}`)
      .then(resp => {
        // console.log("get", resp.data);
        setShareBlocks(resp.data.blocks);
      })
    }
  };
  
  const CloseHandler = ()=> {
    setOpen("");
  };
  
  
  // const [ active, setActive ] = useState(false);
  // const ShowOpen = (type)=> {
  //   setShow(type);
  // };
  

  const BarCSS = css`
    .items_bar_top {
      height: 140px;
    }
   .items_box {
      top: 140px;
      height: calc(100% - 210px);
    }
    .items_bar_bottom {
      display: block;
    }
    
    @media screen and (max-width: 500px) {
      .items_bar_top {
        height: 100px;
      }
      .items_box {
        top: 100px;
        height: calc(100% - 150px);
        padding: 1rem 2rem 2rem;
      }
    }
  `

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar" css={BarCSS}>
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロックの追加
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
          
          <div className="tab_menu">
            <button
              className={`tab_menu_btn ${show !== "Block" && "active"}`}
              style={{width: "calc(100% / 2 - 5px)"}}
              onClick={()=>setShow("Part")}
            >パーツ選択</button>
            <button
              className={`tab_menu_btn ${show == "Block" && "active"}`}
              style={{width: "calc(100% / 2 - 5px)"}}
              onClick={GetShareBlocks}
            >共有ブロック</button>
          </div>
        </div>
        
        <div className="items_box select_type" 
          name={`section_${page_id}`}
        >
          {show == "Part" &&
            <>
              {PartCategory.map((category, index) => {
                return (
                  <button key={index}
                    className={`type_btn ${show == category.type && "active"}`}
                    onClick={()=>setShow(category.type)}
                  >
                    {category.name}
                  </button>
                );
              })}
            </>
          }

          {show !== "Block" &&
            <>
              {show !== "Part" &&
                <button className="clear_btn mb_2" onClick={()=>setShow("Part")}>戻る</button>
              }
              
              <PartSelect 
                obj={section}
                show ={show}
                PartTypeChange={PartTypeChange}
              />
            </>
          }
    
          {show == "Block" &&
            <ShareBlocksSelect 
              obj={section} 
              shareBlocks={shareBlocks} 
              SelectItem={SelectItem} 
            />
          }
        </div>
        
        <div className="items_bar_bottom">
          {show !== "Block" 
            ? <button className="save_btn" onClick={()=>AddSection("sections")}>ブロックを追加する</button>
            : <button className="save_btn" onClick={()=>AddSection("sections/add_shareblock")}>共有ブロックを追加する</button>
          }
        </div>
        
      </div>
    </>
  );
});

// <button className={ btnClass !== undefined ? `${btnClass}` : sharing == null ? "btn_section_add" : "btn_add"} onClick={OpenHandler}>
//         <span><BiPlus/>パーツを追加</span>
//       </button>