import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
// import { StateContext, SaveDataContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';

import { Layout } from '@/page/Layout';
import { Nav } from '@/product/Nav';
import { WidthSwitch } from '@/app/WidthSwitch';
import { ShareSections } from '@/sharing/ShareSections';


export const HeaderSections = React.memo(() => {
  
  const { productId } = useParams();
  // const { client } = useContext(StateContext);
  
  const [ width, setWidth ] = useState("");
  const widthState = {
    width, setWidth
  }
  
  
  return (
    <>
      <Layout type="sharing">
      
        <div className="wrapper">
          <div className="main_layer"></div>
          <Nav/>
          
          <div className="headline_field">
            <h1 className="H2 color_white">ヘッダーの編集</h1>
            <Link to={`/product/${productId}/footer/edit`}>
              <button className="btn_dark">フッター編集へ</button>
            </Link>
          </div>
          
          <div className="color_white mb_4">このヘッダーはすべてのページの上部に表示されます。</div>
          <WidthSwitch widthState={widthState}/>
        </div>
        
        <ShareSections sharing="header" width={width}/>
        
      </Layout>
      
      <style>
        {`
          .navbar .icon{
            display: none;
          }
          .main {
            width: calc(100% - 50px);
          }
          .main_area {
            max-width: 100%;
            padding: 0;
          }
          .sidebar {
            width: 50px;
          }
          @media screen and (max-width: 820px) {
            body {
              padding-bottom: 50px;
            }
            .main {
              width: 100%;
            }
            .sidebar {
              width: 100%;
            }
            .footer {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
});

// <Layout saveAll={saveAll} type="sharing">

                // <Sections sectionsState={sectionsState}/>