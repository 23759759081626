import React from 'react';
import { Link } from 'react-router-dom';
import { TopicPath } from '@/app/TopicPath';


export const Question = () => {
  
  return (
    <>
      <div className="main_layer"></div>
      <TopicPath topic="よくある質問"/>
    
      <div className="base_area">
        <h1 className="H1">よくある質問</h1>

        <h2 className="H2_line mt_2">新しいプロダクトが作成できません。どうすれば？</h2>
        <p>
          新しいプロダクトを作成には、既存プロダクトのプランを「有料プラン」に変更する必要があります。<br/>
          既存プロダクトの中にフリープランがある場合、新しいプロダクトが作成できない状態です。
        </p>
        
        <h2 className="H2_line">新しいページが作成できません。どうすれば？</h2>
        <p>
          プランにより、作成できるページ数に制限があります。作成可能なページ数は下記で確認できます。<br/>
          <Link to="/addons">料金プランへ</Link>
        </p>
        
        <h2 className="H2_line">決済方法を教えて。</h2>
        <p>現在、当サービスで利用いただける決済方法は「クレジットカード決済のみ」となります。</p>
        
        <h2 className="H2_line">独自ドメインにするには？</h2>
        <p>
          独自ドメインにするには、ライトプラン以上のプランに変更して頂く必要があります。<br/>
          設定方法はこちら ➡：<a href="https://pstock.jp/domain-setting" target="_blank" rel="noopener noreferrer">独自ドメインの取得と設定方法へ</a>
        </p>
        
        <h2 className="H2_line">有料プランやオプションの解約は、いつでもできるの？</h2>
        <p>いつでも解約可能です。</p>
        
        <h2 className="H2_line">有料プランやオプションを途中で解約した場合、返金はあるの？</h2>
        <p>いつのタイミングで解約されても返金には対応しておりません。</p>
      </div>
    </>
  );
};