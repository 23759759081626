import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams, useNavigate, Link } from 'react-router-dom';
// import TextareaAutosize from 'react-textarea-autosize';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { Nav } from '@/product/Nav';
import { BaseSetting  } from '@/product/BaseSetting';
import { DomainSetting  } from '@/product/DomainSetting';
import { AddSetting  } from '@/product/AddSetting';
import { CodeSetting  } from '@/product/CodeSetting';
import { SnsSetting  } from '@/product/SnsSetting';
import { ImgSetting  } from '@/product/ImgSetting';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { useForm } from 'react-hook-form';
import { Subject } from '@/product/Subject';
import { BiX } from "react-icons/bi";
import imageCompression from 'browser-image-compression';

import { useRecoilState } from 'recoil'
import { addonsAtom, productAtom } from '@/providers/Atoms';


export const ProductSetting = React.memo(({ productsState }) => {
  
  const { client, s3key } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  const { setProducts } = productsState
  // const [ product, setProduct ] = useState([]);
  const [ product, setProduct ] = useRecoilState(productAtom)
  const [ setting, setSetting ] = useState([]);
  // const [ addons, setAddons ] = useState([]);
  const [ addons, setAddons ] = useRecoilState(addonsAtom)
  // console.log(addons);

  const { productId } = useParams();
  
  // パラメータ取得
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || "Base";
  // console.log(key);
  
  // バリデーションフォーム
  const { 
    register, 
    handleSubmit, 
    setValue, 
    formState: { errors }, 
    reset, 
    getValues, 
    watch,
    resetField,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    // defaultValues: { name: product.name, domain: "ドメイン"},
  });
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    client.get(`/api/v1/products/${productId}/setting`)
    .then(resp => {
      setProduct(resp.data.product);
      setSetting(resp.data.setting);
      setAddons(resp.data.addons);
      // setValue('name', resp.data.product.name);
      // setValue('dom', resp.data.product.domain);
      
      // フォームのValueをセット
      // Object.entries(resp.data.product).map((data) => {
      //   setValue(data[0], data[1]);
      // })
      // setValue("loading", resp.data.setting.loading);
      reset({...resp.data.product, ...resp.data.setting});
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  

  // console.log("SEO", watch('optimize'));
  // console.log("インデックス", watch('index'));
  
  // const InputChange = event => {
  //   const { name, value } = event.target;
  //   setProduct({ ...product, [name]: value });
  // };
  
  // const SettingChange = event => {
  //   const { name, value } = event.target;
  //   setSetting({ ...setting, [name]: value });
  // };
  

  // // プロダクト編集
  // const updateProduct = () => {
  //   client.patch(`/api/v1/products/${product.id}`, product)
  //   .then(resp => {
  //     if(productId !== resp.data.subdomain ){
  //       navigate(`/${resp.data.subdomain}/setting`);
  //     }
  //     setToastObj({message: "編集しました", color: "#D1E7DD"});
  //   })
  //   .catch(e => {
  //     setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
  //     console.log(e.response.data);
  //   });
  // };
  
  
  // // 設定編集
  // const updateSetting = () => {
  //   client.patch(`/api/v1/settings/${setting.id}`, setting)
  //   .then(resp => {
  //     setToastObj({message: "編集しました", color: "#D1E7DD"});
  //   })
  //   .catch(e => {
  //     setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
  //     console.log(e.response.data);
  //   });
  // };

  
  // 削除
  const deleteProduct = () => {
    const sure = window.confirm(`このプロダクト「${product.subdomain}」を本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/products/${product.id}`)
      .then(resp => {
        setProducts((prevState) =>
          prevState.filter((obj) => obj.id !== product.id)
        );
        
        navigate('/home');
        setToastObj({message: "削除しました", color: "#D1E7DD"});
      })
      .catch(e => {
        // setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // クリア
  const Clear = (column) => {
    // const newProduct = {...product, [column]: null};
    // setProduct(newProduct);
    // reset({[column]: null});
    setValue(column, null);
    // resetField(column);
  }
  
  
  // アクセスキー設定
  const s3Client = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
      accessKeyId: s3key.accesskey,
      secretAccessKey: s3key.secretkey,
    },
  });
  

  // s3に画像アップ
  const InputFileUp = (file, name) => {
    
    const Params = {
      ACL: 'public-read',
      Bucket: s3key.bucket,
      Key: `image/product/${product.subdomain}/${name}/${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    
    s3Client.send(new PutObjectCommand(Params))
    .then(resp => {
      const url = `https://${Params.Bucket}.s3.ap-northeast-1.amazonaws.com/${Params.Key}`
      console.log("画像アップロード完了");
      setValue(name, url);
      // setProduct({ ...product, [name]: url });
    })
    .catch(e => {
      setToastObj({message: "アップロードに失敗しました", color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 画像取得＆アップロード
  const FileUpload = async (e) => {
    const { name } = event.target;
    const file = e.target.files[0];
    
    // if (name == "icon" && file.size/1024 > 100) {
    //   setToastObj({message: "設定できません！サイズオーバーです。", color: "#ffedab"});
    //   console.log("画像サイズオーバー");
    //   return;
    // }
    
    // if (name == "img" && file.size/1024 > 500) {
    //   setToastObj({message: "設定できません！サイズオーバーです。", color: "#ffedab"});
    //   console.log("画像サイズオーバー");
    //   return;
    // }
    
    if (name == "img") {
      try {
        if (file.size/1024 > 500) {
          console.log("リサイズ");
          // リサイズ内容
          const options = await {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          }
          // リサイズ処置
          const ResizeFile = await imageCompression(file, options);
          InputFileUp(ResizeFile, name);
  
        } else {
          console.log("リサイズなし");
          InputFileUp(file, name);
        }
      } catch (e) {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.error(e);
      }
    }
    
    if (name == "icon") {
      try {
        if (file.size/1024 > 100) {
          console.log("リサイズ");
          // リサイズ内容
          const options = await {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 300,
            useWebWorker: true,
          }
          // リサイズ処置
          const ResizeFile = await imageCompression(file, options);
          InputFileUp(ResizeFile, name);
  
        } else {
          console.log("リサイズなし");
          InputFileUp(file, name);
        }
      } catch (e) {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.error(e);
      }
    }

  };
  

  const [ show, setShow ] = useState("Base");
  
  const ShowOpen = (type)=> {
    setShow(type);
    
    // if(/Base|Domain|Img/.test(type)){
    //   reset(product);
    // } else if(/Sns|Code|Add/.test(type)){
    //   reset(setting);
    // }
  };
  
  // アドオンの名前を配列に
  // const AddonNames = addons.map((obj) => {
  //   return obj.addon_name
  // });
  
  // モーダルオープン
  const [ open, setOpen ] = useState(false);
  
  
  // プロダクト保存
  const ProductSubmit = (data) => {

    if (/www|magazine|company|guide|pagestock|sample/.test(data.subdomain)) {
      setToastObj({message: "プロダクトIDに使用できない文字が含まれています。", color: "#ffedab"});
      return;
    }
    
    // 「""」が一意制にひっかかるため「null」に変更
    let newData;
    if (data.domain == "") {
      newData = { ...data, domain: null}
    } else {
      newData = data
    }
    // console.log(newData); 
    
    client.patch(`/api/v1/products/${product.id}`, newData)
    .then(resp => {
      if(productId !== resp.data.subdomain ){
        navigate(`/product/${resp.data.subdomain}/setting?key=Domain`);
      }
      // reset(resp.data);
      setProduct(resp.data);
      setToastObj({message: "変更を保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e.response.data);
    });
  };
  
  // セッティング保存
  const SettingSubmit = (data) => {
    // console.log("データ", data);
    client.patch(`/api/v1/settings/${setting.id}`, data)
    .then(resp => {
      // reset(resp.data);
      setSetting(resp.data);
      setToastObj({message: "変更を保存しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e.response.data);
    });
  };
  

  return (
    <>
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>
      
      <h1 className="H1 color_white pb_1">プロダクトの設定</h1>
      <div className="color_white mb_1">
        このプロダクトの各種の設定ができます。
      </div>
      
      <div className="base_area">
      
        <div className="tab_menu">
          <Link to={`/product/${productId}/setting`}
            className={`tab_menu_btn ${key == "Base" && "active"}`} 
          >基本設定</Link>
          <Link to={`/product/${productId}/setting?key=Domain`}
            className={`tab_menu_btn ${key == "Domain" && "active"}`} 
          >ドメイン設定</Link>
          <Link to={`/product/${productId}/setting?key=Img`}
            className={`tab_menu_btn ${show == "Img" && "active"}`} 
          >画像設定</Link>
          <Link to={`/product/${productId}/setting?key=Sns`}
            className={`tab_menu_btn ${show == "Sns" && "active"}`} 
          >SNS設定</Link>
          <Link to={`/product/${productId}/setting?key=Code`}
            className={`tab_menu_btn ${show == "Code" && "active"}`} 
          >外部コード設定</Link>
          <Link to={`/product/${productId}/setting?key=Add`}
            className={`tab_menu_btn ${show == "Add" && "active"}`} 
          >追加設定</Link>
        </div>
        
        { /Base|Domain|Img/.test(key) &&
          <form onSubmit={handleSubmit(ProductSubmit)}>
            
            {key == "Base" &&
              <BaseSetting 
                // product={product}
                // addons={addons}
                // InputChange={InputChange}
                deleteProduct={deleteProduct}
                register={register}
                errors={errors}
                // getValues={getValues}
                watch={watch}
                Clear={Clear}
              />
            }
            
            {key == "Domain" &&
              <DomainSetting
                product={product}
                // addons={addons}
                register={register}
                errors={errors}
              />
            }
            
            {key == "Img" &&
              <ImgSetting 
                // product={product}
                // setting={setting}
                // InputChange={InputChange}
                FileUpload={FileUpload}
                watch={watch}
                Clear={Clear}
              />
            }
            
            <button type="submit" className="btn_add mt_3">保存する</button>
          </form>
        }
        
        {/Sns|Code|Add/.test(key) &&
          <form onSubmit={handleSubmit(SettingSubmit)}>
          
            {key == "Sns" &&
              <SnsSetting 
                // setting={setting}
                // SettingChange={SettingChange}
                register={register}
                errors={errors}
                Clear={Clear}
              />
            }
            
            {key == "Code" &&
              <CodeSetting 
                // setting={setting}
                // SettingChange={SettingChange}
                // addons={addons}
                register={register}
                errors={errors}
                Clear={Clear}
              />
            }
            
            {key == "Add" &&
              <AddSetting 
                // setting={setting}
                // SettingChange={SettingChange}
                // addons={addons}
                register={register}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                Clear={Clear}
              />
            }
            
            <button type="submit" className="btn_add mt_3">設定を保存</button>
          </form>
        }
          
      </div>
      
      {key == "Base" &&
        <div className="base_area" style={{paddingTop:"0px"}}>
          <h2 className="H2_line mt_1" style={{fontSize:"14px"}}>プロダクトの削除</h2>
          
          { addons.length > 0
           ?
            <>
              <p>削除するには「有料プラン、オプション」をすべて解除してください。</p>
              <button className="btn_delete" disabled={true}>削除する</button>
            </>
           : <button className="btn_delete" onClick={()=>setOpen(true)}>削除する</button>
          }
        </div>
      }
      
      {open &&
        <div className="modal" onClick={()=>setOpen(false)}>
          <div className="modal_window" onClick={(e) => e.stopPropagation()}>
            <div className="modal_content">

              <h2 className="H2_line color_red mt_1">本当にこのプロダクトを削除しますか？</h2>
              <div>■プロダクトID：{product.subdomain}</div>
              <div>■独自ドメイン：{product.domain || "未設定"}</div>
              <div>■サイト名：{product.name || "未設定"}</div>

              <button className="btn_delete mt_3" onClick={deleteProduct}>削除する</button>
             
            </div>
            <div className="close_btn" onClick={()=>setOpen(false)}><BiX/></div>
          </div>
        </div>
      }
      
    </>
  );
});


// <button 
//   className={`tab_menu_btn ${show == "Base" && "active"}`} 
//   onClick={()=>ShowOpen("Base")}
// >基本設定</button>
// <button 
//   className={`tab_menu_btn ${show == "Domain" && "active"}`} 
//   onClick={()=>ShowOpen("Domain")}
// >ドメイン設定</button>
// <button
//   className={`tab_menu_btn ${show == "Img" && "active"}`} 
//   onClick={()=>ShowOpen("Img")}
// >画像設定</button>
// <button
//   className={`tab_menu_btn ${show == "Sns" && "active"}`} 
//   onClick={()=>ShowOpen("Sns")}
// >SNS設定</button>
// <button 
//   className={`tab_menu_btn ${show == "Code" && "active"}`} 
//   onClick={()=>ShowOpen("Code")}
// >コード設定</button>
// <button
//   className={`tab_menu_btn ${show == "Add" && "active"}`} 
//   onClick={()=>ShowOpen("Add")}
// >追加設定</button>

          // <div className="mt_4">
          //   {show == "Base" || show == "Domain" || show == "Img"
          //     ? <button className="" onClick={updateProduct}>保存</button>
          //     : <button className="" onClick={updateSetting}>設定を保存</button>
          //   }
          // </div>
          
          
          // <button 
          //   className={show == "Code"? "active":""} 
          //   onClick={()=>ShowOpen("Code")}
          // >SEO設定</button>


        // <img src={img || "/1.jpg"} height="100" width="100"/>
        // <input type="file" name="img" accept="image/*" onChange={InputFile} />
        
        // <SelectImg obj={product} InputChange={InputChange} Clear={Clear} type="product"/>
        
        
        
  // 画像アップ
  // const InputFile = event => {
  //   const { name, value } = event.target;
  //   console.log(event.target.files[0]);

  //   const formData = new FormData()
  //   formData.append('product[img]', event.target.files[0]) // ポイント1！
  //   // formData.append('product[title]', "tesuto") // ポイント1！
  //   console.log(formData);
    
  //   // setProduct({ ...product, [name]: "", formData });
    
  //   client.patch(`/api/v1/products/${product.id}`, formData)
  //   .then(resp => {
  //     console.log("画像投稿しました。");
  //   })
  // };
