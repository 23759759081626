import React, { useState } from "react"
// import { outlineCSS } from '@/part/type/Style';
import parse from 'html-react-parser';
import { Textarea } from '@/text/Textarea';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";


export const HtmlTable_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("テーブルパーツレンダ");
  const { content, setContent } = textStates;
  const { contentHandler } = textHandlers
  
  const [ open, setOpen ] = useState("html");
  
  let backColors = part.back_color ? part.back_color.split("／") : [];
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const tableCSS = css`
    color: ${part.font_color};
    font-family: ${part.font};
    text-align: ${part.align};
    box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
      
    table {
      border-color: ${part.border_color};
      border-width : ${part.border && `${part.border}px`};
      border-radius: ${part.radius && `${part.radius}px`};
    }
    th, td {
      padding: ${part.padding && `${part.padding}px`};
      border-color: ${accentColors[1]};
    }
    th {
      background: ${backColors[0]};
      ${backColors.length > 1 && `
        background: linear-gradient(130deg, ${backColors[0] || "transparent"} 35%, ${backColors[1]} 100%);
      `}
      
      color: ${accentColors[0]};
    }
  `
  
let tr = `
  <tr>
    <th>項目</th>
    <td>コンテンツ</td>
  </tr>
`.repeat(4);
  
const tableValue = `<table class="">
  <tbody>
    ${tr}
  </tbody>
</table>
`

  
  return (
    <>
      <div className="visual_switch html_visual_switch">
        <button onClick={()=>setOpen("html")}
          className={ open == "html" ? "active" :""}
        >入力</button>
        <button onClick={()=>setOpen("visual")}
          className={ open == "visual" ? "active" :""}
        >表示</button>
      </div>
      
      {open == "html"
       ?
        <>
          <div className="html_title">テーブルのHTML</div>
          <div className="html_input_box">
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              tableValue={tableValue}
              placeholder="HTMLを入力してください。"
              clickHandler={contentHandler}
            />
          </div>
        </>
      :
        <div 
          className={`table_1 ${part.class_name} ${part.add_class||""}`}
          css={tableCSS}
          id={part.add_id}
        >
          {parse(`${content.content || tableValue}`)}
        </div>
      }
    </>
  );
  
});


// export const HtmlTable_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("テーブルパーツレンダ");
  
//   let tr = `<tr>
//               <th>項目</th>
//               <td>コンテンツ</td>
//           </tr>
//           `.repeat(4);
  
//   const value = `<table class="">
//       <tbody>
//         ${tr}
//       </tbody>
//     </table>
//   `

  
//   return (
//     <>
//       <div className="html_title">テーブルのHTML</div>
      
//       <div className="html_input_box">
//         <TextareaAutosize 
//           type="text"
//           // minRows={4}
//           name="content" 
//           value={part.content || value} 
//           onChange={InputChange} 
//           placeholder="HTMLを入力してください。"
//         />
//       </div>
      
//       <div className="mb_1">こちらが出力されます。</div>
//       <div 
//         className={`${part.class_name} ${part.add_class||""}`}
//         id={part.add_id}
//       >
//         {parse(`${part.content || value}`)}
//       </div>
//     </>
//   );
  
// });
