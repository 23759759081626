import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';


export const Point_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("ポイント１パーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  const judge1_3or4 = part.class_name == "point_1-3" || part.class_name == "point_1-4"
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const pointCSS = css`
    ${outlineCSS(part)}
    
    .point_img {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
    
    ${judge1_3or4 && accentColors[0] && `
      .point_img {
        img, video {
          box-shadow: 15px 15px 0 ${accentColors[0]};
        }
      }
    `}
  `
  
    //   ${ part.class_name == "point_2-1" && img_0.radius && `
    //   .point_img img {
    //     border-radius: ${img_0.radius}px 0 0 ${img_0.radius}px;
    //   }
    // `}
    // ${ part.class_name == "point_2-2" && img_0.radius && `
    //   .point_img img {
    //     border-radius: 0 ${img_0.radius}px ${img_0.radius}px 0;
    //   }
    // `}

  return (
    <>
      <div 
        className={`point_1 ${part.class_name} ${part.add_class || ""}`}
        css={pointCSS}
        id={part.add_id}
      >
        <div className="point_body">
          
          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={placeholder.subtitle}
              clickHandler={subtitleHandler}
            />
          </div>
          
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h3>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              placeholder={placeholder.content}
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link &&
            <a className="part_btn">MORE</a>
          }
        </div>
        
        <div className="point_img">
          <PartImg
            obj={img_0}
            setObj={setImg_0}
          />
        </div>
        
      </div>
    </>
  );
  
});


      // { part.class_name == "point_2-1" && img_0.radius && 
      //   <style>
      //   {`
      //     .part_box:nth-of-type(2n) .point_2-1 .point_img img {
      //       border-radius: 0 ${img_0.radius}px ${img_0.radius}px 0;
      //     }
      //   `}
      //   </style>
      // }

      // { part.class_name == "point_2-2" && img_0.radius && 
      //   <style>
      //   {`
      //     .part_box:nth-of-type(2n) .point_2-2 .point_img img {
      //       border-radius: ${img_0.radius}px 0 0 ${img_0.radius}px;
      //     }
      //   `}
      //   </style>
      // }


// export const Point_A = React.memo(({ part, InputChange, setOpen }) => {
  
//   // console.log("ポイント１パーツレンダ");
  
//   const pointCSS = css`
//     ${outlineCSS(part)}
//     .point_img img{
//       border-radius: ${part.radius && `${part.radius}px`};
//     }
//     .title {
//       ${fontCSS(part)}
//       ${accentCSS(part)}
//     }
//     .subtitle {
//       color: ${part.font_color};
//       color: ${part.border_color};
//     }
//     p {
//       color: ${part.font_color};
//     }
//   `

//   return (
//     <>
//       <div 
//         className={`point_1 ${part.class_name} ${part.add_class || ""}`}
//         // style={outlineStyle(part)} 
//         css={pointCSS}
//         id={part.add_id}
//       >
//         <div className="point_body">
          
//           <div className="subtitle">
//             <TextareaAutosize
//               type="text" 
//               name="subtitle" 
//               value={part.subtitle || ''} 
//               onChange={InputChange}
//               placeholder={placeholder.subtitle}
//             />
//           </div>
          
//           <h3 className="title">
//             <TextareaAutosize 
//               type="text" 
//               name="title" 
//               value={part.title || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.title}
//             />
//           </h3>
          
//           <p>
//             <TextareaAutosize
//               type="text"
//               // minRows={3}
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.content}
//             />
//           </p>
          
//           {part.link &&
//             <a className="part_btn">MORE</a>
//           }
//         </div>
        
//         <div className="point_img" onClick={()=>setOpen("Img")}>
//           <img src={part.img || placeholder.img} alt={part.alt}/>
//         </div>
        
//       </div>
//     </>
//   );
  
// });