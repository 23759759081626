import React, { useState, useEffect, useContext } from 'react';
// import { outlineStyle } from '@/part/type/Style';
import { StateContext } from '@/providers/StateProviders';
import { MenuSelect } from '@/part/MenuSelect';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';
import { ImgSetting } from '@/image/ImgSetting';



export const Footer_A = React.memo(({ part, imgs, InputChange, openState, Clear, textStates, textHandlers }) => {
  
  // console.log("フッターパーツレンダ");
  const { title, setTitle, subtitle, setSubtitle } = textStates;
  const { titleHandler, subtitleHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  const [ spSwitch, setSpSwitch ] = useState("BASE");
  const spSwitchState = { spSwitch, setSpSwitch }
  
  const { open, setOpen } = openState;
  
  const { client } = useContext(StateContext);
  const [ items, setItems ] = useState([]);
  
  const GetMenu = (id) => {
    if(part?.menu_id !== null){
      client.get(`/api/v1/menus/${id}`)
      .then(resp => {
        setItems(resp.data.items);
      }) 
    }
  };
  
  useEffect(() => {
    GetMenu(part.menu_id);
  }, []);

  
  // let fontColors = title.font_color ? title.font_color.split("／") : [];

  const footerCSS = css`
    ${outlineCSS(part)}
    
    .logo {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .title {
      ${textCSS(title)}
    }
    .subtitle {
      ${textCSS(subtitle)}
    }
    .footer_body a {
      color: ${part.font_color};
    }
  `
  
  const [ active, setActive ] = useState(false);
  
  const OpenHandler = () => {
    setActive(true);
  };
  
  
  return (
    <>
      <div className="menu_select_btn" onClick={OpenHandler}>メニューから選択</div>
      {active &&
        <MenuSelect 
          part={part} 
          InputChange={InputChange} 
          GetMenu={GetMenu} 
          setItems={setItems} 
          Clear={Clear}
          setActive={setActive}
        />
      }
      
      <div
        className={`footer_1 ${part.class_name} ${part.add_class||""}`}
        css={footerCSS}
        id={part.add_id}
      >
        <div className="footer_header">

          {part.class_name !== "footer_2-1" &&
            <>
              <div className="logo"
                style={{display: img_0.img ? "block" : "none"}}
              >
                <PartImg
                  obj={img_0}
                  setObj={setImg_0}
                />
              </div>
              
              <div className="title"
                style={{display: img_0.img ? "none" : "block"}}
              >
                <Textarea
                  part_id={part.id}
                  text_type="title"
                  obj={title}
                  setObj={setTitle}
                  placeholder={placeholder.title}
                  clickHandler={titleHandler}
                />
                <div className="img_select_btn" onClick={()=>setOpen("Img")}>
                  ロゴ画像を選択
                </div>
              </div>
            </>
          }

          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={placeholder.subtitle}
              clickHandler={subtitleHandler}
            />
          </div>
        </div>
        
        <div className="footer_body">
          {part?.menu_id !== null
          　?
              <>
                {items.map((item) => {
                  return (
                    <a key={item.id}>
                      {item.name}
                    </a>
                  );
                })}
              </>
            :
              <>
                <a>
                  右側の「メニューから選択」からメニューを選択してください。メニューがない場合は作成してください。
                </a>
              </>
          }
        </div>
        
      </div>
      
      { open == "Img" &&
        <ImgSetting 
          obj={img_0} 
          setObj={setImg_0}
          type="part"
          setOpen={setOpen}
          spSwitchState={spSwitchState}
        />
      }

    </>
  );
  
});

// { open == "Img" &&
//   <>
//     {spSwitch == "PC"
//       ? <ImgSetting 
//           obj={img_pc}
//           setObj={setImg_pc}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//       : <ImgSetting 
//           obj={img_sp}
//           setObj={setImg_sp}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//     }
//   </>
// }

// { open == "Img" &&
//     <ImgSetting 
//       obj={img_0} 
//       setObj={setImg_0}
//       type="part"
//       setOpen={setOpen}
//       spSwitchState={spSwitchState}
//     />
//   }
// </>


  // const footerCSS = css`
  //   ${outlineCSS(part)}
    
  //   .logo {
  //     img, video {
  //       ${imgCSS(img_0)}
  //     }
  //   } 
  //   .title {
  //     ${textCSS(title)}
  //   }
  //   .subtitle {
  //     ${textCSS(subtitle)}
  //   }
  //   .footer_body a {
  //     color: ${part.font_color};
  //   }
  // `

// export const Footer_A = React.memo(({ part, InputChange, setOpen, Clear }) => {
  
//   // console.log("フッターパーツレンダ");
  
//   const { client } = useContext(StateContext);
//   const [ items, setItems ] = useState([]);
  
//   const GetMenu = (id) => {
//     if(part?.menu_id !== null){
//       client.get(`/api/v1/menus/${id}`)
//       .then(resp => {
//         setItems(resp.data.items);
//       }) 
//     }
//   };
  
//   useEffect(() => {
//     GetMenu(part.menu_id);
//   }, []);
  

//   const footerCSS = css`
//     ${outlineCSS(part)}
//     .footer_header {
//       ${fontCSS(part)}   
//     };
//     .footer_body .item {
//       color: ${part.font_color};
//     }
//   `
  
//   const [ active, setActive ] = useState(false);
  
//   const OpenHandler = () => {
//     setActive(true);
//   };
  
  
//   return (
//     <>
//       <button className="menu_select_btn" onClick={OpenHandler}>メニューから選択</button>
//       {active &&
//         <MenuSelect 
//           part={part} 
//           InputChange={InputChange} 
//           GetMenu={GetMenu} 
//           setItems={setItems} 
//           Clear={Clear}
//           setActive={setActive}
//         />
//       }
      
//       <div
//         className={`footer_1 ${part.class_name} ${part.add_class||""}`}
//         css={footerCSS}
//         id={part.add_id}
//       >
//         <div className="footer_header">
//           <div className="logo" onClick={()=>setOpen("Img")}>
//             <img src={part.img || "/logo_wide_black.png"} alt={part.alt}/>
//           </div>
          
//           <div className="title">
//             <input 
//               type="text"
//               name="title"
//               value={part?.title || ""}
//               onChange={InputChange}
//               placeholder={placeholder.title}
//             />
//           </div>

//           <div className="subtitle">
//             <input 
//               type="text"
//               name="subtitle"
//               value={part?.subtitle || ""}
//               onChange={InputChange}
//               placeholder={placeholder.subtitle}
//             />
//           </div>
//         </div>
        
//         <div className="footer_body" >
//           {part?.menu_id !== null
//           　?
//               <>
//                 {items.map((item) => {
//                   return (
//                     <a className="item" key={item.id}>
//                       {item.name}
//                     </a>
//                   );
//                 })}
//               </>
//             :
//               <>
//                 <a className="item">
//                   右側になる「メニューから選択」からメニューを選択してください。メニューがない場合は作成してください。
//                 </a>
//               </>
//           }
//         </div>
        
//       </div>
//     </>
//   );
  
// });
