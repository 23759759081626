import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


const Wrapper = React.memo(({ children, type }) => {
  const Judge = type == "headline_4-1" || type == "headline_4-2" || type == "headline_4-3" || type == "headline_4-4"
  
  if(Judge) {
    return (<div className="block_wrapper">{children}</div>);
  } else {
    return (<>{children}</>);
  }
});

  
export const Headline_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log(part.back_color.split(" "));
  const { title, setTitle, subtitle, setSubtitle } = textStates;
  const { titleHandler, subtitleHandler } = textHandlers
  
  
  const judge3_3 = part.class_name == "headline_3-3"
  const judge3_4 = part.class_name == "headline_3-4"
  const judge4 = part.class_name == "headline_4-3" || part.class_name == "headline_4-4"
  const judge5_1 = part.class_name == "headline_5-1"
  const judge5_2 = part.class_name == "headline_5-2"
  const dummyJudge = judge5_1 || judge5_2
  
  let backColors = part.back_color ? part.back_color.split("／") : [];
  
  const headlineCSS = css`
    ${outlineCSS(part)}
    box-shadow: ${judge3_4 && `8px 8px 0 ${part.border_color}`};
    
    ${dummyJudge && `
      background: transparent;
      border: none;
      box-shadow: none;
    `}

    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      ${textCSS(subtitle)}
    }
    :before, :after {
      background: ${ part.border_color};
      background: ${ judge3_3 && "transparent"};
      border-top-color: ${ part.border_color };
    }
    
    ${judge4 && `
      :before, :after {
        background: transparent;
        border-top-color: ${backColors[0]};
      }
      :after {
        border-top-color: ${part.border_color};
      }
    `}
    
    ${part.class_name == "headline_5-3" && `
      :before {
        border-top-color: ${backColors[0]};
        background: transparent;
      }
      :after {
        border-top-color: ${part.border_color};
        background: transparent;
      }
    `}
    
    ${part.class_name == "headline_5-4" && `
      :before {
        border-left-color: ${backColors[0]};
        background: transparent;
      }
      :after {
        border-left-color: ${part.border_color};
        background: transparent;
      }
    `}
    
    .dummy {
      ${outlineCSS(part)}
      ${textCSS(title)}
      color: transparent;
      
      ${part.align == "center" && `
        left: 50%;
        transform: translateX(-50%);
      `}
      ${part.align == "right" && `
        left: auto;
        right: 0;
      `}
      
      ${judge5_2 && `
        background: linear-gradient(to right, ${backColors[0] || "#ffff7f"} 0%, ${backColors[1] || backColors[0] || "#ffff7f"} 100%);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100% 30%;
      `}
    }
  `
  
  const titleValue = part.class_name == "headline_6-1" ? "ページストック" : placeholder.title
  const subtitleValue = part.class_name == "headline_6-1" ? "PAGE STOCK" : placeholder.subtitle
  
  // const [ active, setActive ] = useState(false);
  
  // // フォーカスを外す
  // const removeFocus = ()=> {
  //   const elem = document.activeElement;
  //   elem.blur();
  //   setActive(false);
  // };
  
  // const activeFocus = ()=> {
  //   setActive(true);
  //   console.log("カス");
  // };
  
        // <h2 className="headline_1 headline_12">
      //   <div className="title">{part.title ||''}</div>
      // </h2>
  
  return (
    <>
      <h2 
        className={`headline_1 ${part.class_name} ${part.add_class||""}`}
        css={headlineCSS}
        id={part.add_id}
      >
        <Wrapper type={part.class_name}>
          <div className="title">
            {dummyJudge &&
              <div className="dummy">{title.content ||''}</div>
            }
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={titleValue}
              clickHandler={titleHandler}
            />
          </div>
          
          <div className="subtitle">
            <Textarea
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={subtitleValue}
              clickHandler={subtitleHandler}
            />
          </div>
        </Wrapper>
        
      </h2>
    </>
  );
  
});


// export const Headline_A = React.memo(({ part, InputChange }) => {
  
//   // console.log(part.back_color.split(" "));
  
  
//   const judge3_3 = part.class_name == "headline_3-3"
//   const judge3_4 = part.class_name == "headline_3-4"
//   const judge4 = part.class_name == "headline_4-3" || part.class_name == "headline_4-4"
//   const judge5 = part.class_name == "headline_5-1"
  
//   const headlineCSS = css`
//     ${outlineCSS(part)}
//     box-shadow: ${judge3_4 && `8px 8px 0 ${part.border_color}`};
//     background: ${judge5 && "transparent"};
//     border: ${judge5 && "none"};
//     box-shadow: ${judge5 && "none"};

//     .title {
//       ${fontCSS(part)}
//       ${accentCSS(part)}
//     }
//     .subtitle {
//       color: ${part.font_color};
//       color: ${part.border_color};
//       text-shadow: ${part.text_shadow && `2px 2px ${part.text_shadow}px rgba(0, 0, 0, 0.3)`};
//     }
//     :before, :after {
//       background: ${ part.border_color};
//       background: ${ judge3_3 && "transparent"};
//       background: ${ judge4 && "transparent"};
//       border-top-color: ${ part.border_color };
//       border-top-color: ${ judge4 && part.back_color};
//     }
//     :after {
//       border-top-color: ${ judge4 && part.border_color};
//     }
//     .dummy {
//       ${outlineCSS(part)}
//       ${fontCSS(part)}
//       color: transparent;
//     }
//   `
  
//   const value = part.class_name == "headline_6-1" ? "PAGE STOCK" : ""
  
//   // const [ active, setActive ] = useState(false);
  
//   // // フォーカスを外す
//   // const removeFocus = ()=> {
//   //   const elem = document.activeElement;
//   //   elem.blur();
//   //   setActive(false);
//   // };
  
//   // const activeFocus = ()=> {
//   //   setActive(true);
//   //   console.log("カス");
//   // };
  
//         // <h2 className="headline_1 headline_12">
//       //   <div className="title">{part.title ||''}</div>
//       // </h2>
  
//   return (
//     <>
//       <h2 
//         className={`headline_1 ${part.class_name} ${part.add_class||""}`}
//         css={headlineCSS}
//         id={part.add_id}
//       >
//         <Wrapper type={part.class_name}>
//           <div className="title">
//             {judge5 &&
//               <div className="dummy">{part.title ||''}</div>
//             }
//             <TextareaAutosize  
//               type="text" 
//               name="title" 
//               value={part.title ||''}
//               onChange={InputChange} 
//               placeholder={placeholder.title}
//             />
//           </div>
          
//           <div className="subtitle">
//             <TextareaAutosize 
//               type="text"
//               name="subtitle"
//               value={part.subtitle || value}
//               onChange={InputChange}
//               placeholder={placeholder.subtitle}
//             />
//           </div>
//         </Wrapper>
        
//       </h2>
//     </>
//   );
  
// });