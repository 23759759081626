import React, { useState, useContext, useRef } from "react"
import { StateContext } from '@/providers/StateProviders';
import TextareaAutosize from 'react-textarea-autosize';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import parse from 'html-react-parser';
// import { Textarea } from '@/text/Textarea';
import { RichEditor } from '@/text/RichEditor';
import { useAutoSave } from '@/hooks/useAutoSave';

import { useSetRecoilState } from 'recoil'
import { saveStatusAtom } from '@/providers/Atoms';


export const Text_A = React.memo(({ part, setPart, textStates, textHandlers }) => {
  
  // console.log("テキストパーツ","レンダ", part);
  const { content, setContent } = textStates;
  const { contentHandler } = textHandlers
  
  const { client } = useContext(StateContext);
  const setSaveStatus = useSetRecoilState(saveStatusAtom)
  
  const textareaRef = useRef(null);
  
  const [ open, setOpen ] = useState("text");


  const InputChange = event => {
    const { name, value } = event.target;
    setContent((prev)=> ({ ...prev, part_id: part.id, text_type: "content", [name]: value}));
  };
  
  // 保存
  const update = () => {
    client.patch(`/api/v1/texts/${content.id}`, content)
    .then(resp => {
      if(resp.data.id !== content.id ) {
        setContent(resp.data);
        console.log("セット");
      }
      setSaveStatus(false);
      console.log("保存しました");
    })
    .catch(e => {
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(content, update, 3000);

  
  const textCss = css`
    ${outlineCSS(part)}
    
    p {
     ${textCSS(content)}
    }
  `

  return (
    <>
      <RichEditor
        setContent={setContent}
        textareaRef={textareaRef}
        open={open}
        setOpen={setOpen}
      />
        
      <div 
        className={`text_1 ${part.class_name} ${part.add_class||""}`}
        css={textCss}
        id={part.add_id}
      >
        {open == "text"
          ?
          <p>
            <TextareaAutosize
              type="text"
              name="content"
              value={content.content|| ""}
              onChange={InputChange}
              placeholder="テキストを入力..."
              onClick={contentHandler}
              ref={textareaRef}
            />
          </p>
          :
          <p>{parse(`${content.content}`)}</p>
        }
      </div>
    </>
  );
  
});


// <p>
//   <Textarea
//     part_id={part.id}
//     text_type="content"
//     obj={content}
//     setObj={setContent}
//     placeholder="テキストを入力..."
//     clickHandler={contentHandler}
//     // ref={textareaRef}
//   />
// </p>



// <p>
//   <TextareaAutosize 
//     type="text"
//     // minRows={3}
//     name="content" 
//     value={parse(`${part.content || ""}`)} 
//     onChange={InputChange} 
//     placeholder="テキストを入力..."
//   />
// </p>
// {parse(`${part.content || "<p>こちらに出力されます。</p>"}`)}

// <TextareaAutosize
//         minRows={4}
//         id="content"
//         type="text"
//         placeholder="テキストを入力してください。"
//         {...register('content')}
//       />