import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';

import { Sections } from '@/section/Sections';
import { NewSection } from '@/section/NewSection';
import { BiPlus } from "react-icons/bi";
import { PageNav } from '@/page/PageNav';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useSetRecoilState } from 'recoil'
import { saveDataAtom, productAtom } from '@/providers/Atoms';



export const ShareSections = React.memo(({ sharing, width }) => {
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);
  // const { setSaveData } = useContext(SaveDataContext);
  const [ sections, setSections ] = useState([]);
  const [ setting, setSetting ] = useState([]);
  // const setSaveData = useSetRecoilState(saveDataAtom)
  const setProduct = useSetRecoilState(productAtom)
  
  
  useEffect(() => {
    client.get(`/api/v1/sections/sharing?subdomain=${productId}&sharing=${sharing}`)
    .then(resp => {
      setProduct(resp.data.product);
      setSections(resp.data.sections);
      setSetting(resp.data.setting);
      // setSaveData({
      //   sections: resp.data.sections, 
      //   blocks: resp.data.blocks, 
      //   parts: resp.data.parts
      // });
    })
  }, []);
  
  
  const SettingCSS = css`
   font-family: ${setting.font};
   background: ${setting.back_color};
   font-size: ${setting.font_size && `${setting.font_size}px`};
  `
  
  const [ open, setOpen ] = useState(false);
  const OpenHandler = ()=> {
    setOpen(true);
  };
  

  return (
    <>
      <PageNav/>
      
      <div 
        className="page_area"
        style={{maxWidth: width}}
        css={SettingCSS}
      >
        <Sections 
          page_id={null} 
          sections={sections} 
          setSections={setSections} 
          sharing={sharing}
          key={sharing}
        />
      </div>
      
      <div className="wrapper mb_4">
        <button className="btn_add" onClick={OpenHandler}>
          <span><BiPlus/>ブロックを追加</span>
        </button>
      </div>
      
      { open &&
        <NewSection
          page_id={null} 
          sections={sections} 
          setSections={setSections}
          sharing={sharing}
          index={Object.keys(sections).length}
          key={sharing}
          setOpen={setOpen}
        />
      }
    </>
  );
});

