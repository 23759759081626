import React, { useState } from 'react';
import { ImgVideo } from '@/app/ImgVideo';
import { ImgSetting } from '@/image/ImgSetting';
// import { isMobile } from "react-device-detect";


export const PartImg = React.memo(({ obj, setObj }) => {
  
  const [ open, setOpen ] = useState(false);

  const [ spSwitch, setSpSwitch ] = useState("BASE");
  const spSwitchState = { spSwitch, setSpSwitch }

  // const ImgView = () => {
  //   if(isMobile && img_sp.img) {
  //     return <ImgVideo obj={img_sp}/>
  //   } else {
  //     return <ImgVideo obj={img_pc}/>
  //   }
  // }
  

  return (
    <>
      <span onClick={()=>setOpen(true)}>
        <ImgVideo obj={obj}/>
      </span>
      
      { open &&
        <ImgSetting 
          obj={obj}
          setObj={setObj}
          type="part"
          setOpen={setOpen}
          spSwitchState={spSwitchState}
        />
      }
    </>
  );

});

// <span onClick={()=>setOpen(true)}>
//   {ImgView()}
// </span>

// { open &&
//   <>
//     {spSwitch == "BASE"
//       ? <ImgSetting 
//           obj={img_pc}
//           setObj={setImg_pc}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//       : <ImgSetting 
//           obj={img_sp}
//           setObj={setImg_sp}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//     }
//   </>
// }




  // useEffect(() => {
  //     setImg(img_obj || {part_id: part_id, img: null}); 
  // }, []);
  
  // const partImg = ( obj, setObj ) => {
    
  //   const [ open, setOpen ] = useState(false);
    
  //   return (
  //     <>
  //       <span onClick={()=>setOpen(true)}>
  //         <ImgVideo obj={obj}/>
  //       </span>
        
  //       { open &&
  //         <ImgSetting 
  //           obj={obj}
  //           setObj={setObj}
  //           type="part"
  //           setOpen={setOpen}
  //         />
  //       }
  //     </>
  //   );
  // }