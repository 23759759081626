import React from 'react';

export const PresetColors = [
  "rgba(255, 255, 255, 1)",
  "rgba(245, 245, 245, 1)",
  "rgba(235, 235, 235, 1)",
  "rgba(225, 225, 225, 1)",
  "rgba(215, 215, 215, 1)",
  "rgba(205, 205, 205, 1)",
  "rgba(195, 195, 195, 1)",
  "rgba(185, 185, 185, 1)",
  "rgba(175, 175, 175, 1)",
  "rgba(165, 165, 165, 1)",
  "rgba(155, 155, 155, 1)",
  "rgba(145, 145, 145, 1)",
  "rgba(135, 135, 135, 1)",
  "rgba(125, 125, 125, 1)",
  "rgba(115, 115, 115, 1)",
  "rgba(105, 105, 105, 1)",
  "rgba(95, 95, 95, 1)",
  
  "rgba(80, 80, 80, 1)",
  "rgba(65, 65, 65, 1)",
  "rgba(50, 50, 50, 1)",
  "rgba(35, 35, 35, 1)",
  "rgba(20, 20, 20, 1)",
  // "rgba(10, 10, 10, 1)",
  "rgba(0, 0, 0, 1)",
  
  "rgba(255, 255, 0, 1)",
  "rgba(235, 235, 0, 1)",
  "rgba(215, 215, 0, 1)",
  "rgba(195, 195, 0, 1)",
  "rgba(175, 175, 0, 1)",
  "rgba(155, 155, 0, 1)",
  "rgba(135, 135, 0, 1)",
  "rgba(115, 115, 0, 1)",
  "rgba(95, 95, 0, 1)",
  "rgba(75, 75, 0, 1)",
  "rgba(55, 55, 0, 1)",
  "rgba(35, 35, 0, 1)",

  "rgba(0, 255, 0, 1)",
  "rgba(0, 235, 0, 1)",
  "rgba(0, 215, 0, 1)",
  "rgba(0, 195, 0, 1)",
  "rgba(0, 175, 0, 1)",
  "rgba(0, 155, 0, 1)",
  "rgba(0, 135, 0, 1)",
  "rgba(0, 115, 0, 1)",
  "rgba(0, 95, 0, 1)",
  "rgba(0, 75, 0, 1)",
  "rgba(0, 55, 0, 1)",
  "rgba(0, 35, 0, 1)",
  
  "rgba(0, 225, 255, 1)",
  "rgba(0, 235, 235, 1)",
  "rgba(0, 235, 215, 1)",
  "rgba(0, 195, 195, 1)",
  "rgba(0, 175, 175, 1)",
  "rgba(0, 155, 155, 1)",
  "rgba(0, 135, 135, 1)",
  "rgba(0, 115, 115, 1)",
  "rgba(0, 95, 95, 1)",
  "rgba(0, 75, 75, 1)",
  "rgba(0, 55, 55, 1)",
  "rgba(0, 35, 35, 1)",
  
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 235, 1)",
  "rgba(0, 0, 215, 1)",
  "rgba(0, 0, 195, 1)",
  "rgba(0, 0, 175, 1)",
  "rgba(0, 0, 155, 1)",
  "rgba(0, 0, 135, 1)",
  "rgba(0, 0, 115, 1)",
  "rgba(0, 0, 95, 1)",
  "rgba(0, 0, 75, 1)",
  "rgba(0, 0, 55, 1)",
  "rgba(0, 0, 35, 1)",
  
  "rgba(255, 0, 255, 1)",
  "rgba(235, 0, 235, 1)",
  "rgba(215, 0, 215, 1)",
  "rgba(195, 0, 195, 1)",
  "rgba(175, 0, 175, 1)",
  "rgba(155, 0, 155, 1)",
  "rgba(135, 0, 135, 1)",
  "rgba(115, 0, 115, 1)",
  "rgba(95, 0, 95, 1)",
  "rgba(75, 0, 75, 1)",
  "rgba(55, 0, 55, 1)",
  "rgba(35, 0, 35, 1)",
  
  "rgba(255, 0, 0, 1)",
  "rgba(235, 0, 0, 1)",
  "rgba(215, 0, 0, 1)",
  "rgba(195, 0, 0, 1)",
  "rgba(175, 0, 0, 1)",
  "rgba(155, 0, 0, 1)",
  "rgba(135, 0, 0, 1)",
  "rgba(115, 0, 0, 1)",
  "rgba(95, 0, 0, 1)",
  "rgba(75, 0, 0, 1)",
  "rgba(55, 0, 0, 1)",
  "rgba(35, 0, 0, 1)",
  
  "#F9F9F9",
  "#F6F7F9",
  "#03D19E",
  "#051E34",

];

  // 'rgba(255,255,255,1)',
  // 'rgba(200,200,200,1)', 
  // 'rgba(150,150,150,1)', 
  // 'rgba(100,100,100,1)', 
  // 'rgba(50,50,50,1)', 
  // 'rgba(0,0,0,1)', 
  // 'rgba(0,0,255,1)', 
  // 'rgba(0,255,0,1)',
  // 'rgba(255,255,0,1)',
  // 'rgba(255,0,0,1)',