import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
// import { toast } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";
import { BiChevronRight } from "react-icons/bi";
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
// import { Nav } from '@/product/Nav';
// import { PageSetting } from '@/page/PageSetting';
// import { Modal } from '@/app/Modal';
// import { Blocks } from '@/block/Blocks';
// import { NewBlock } from '@/block/NewBlock';
import { Layout } from '@/page/Layout';
import { SlugEdit } from '@/page/SlugEdit';
import { LightSetting } from '@/page/LightSetting';
import { HeaderSections } from '@/page/HeaderSections';
import { FooterSections } from '@/page/FooterSections';
import { PageNav } from '@/page/PageNav';
// import { Header } from '@/header/Header';
import { Sections } from '@/section/Sections';
import { NewSection } from '@/section/NewSection';
import { Loading } from '@/app/Loading';
// import { WidthSwitch } from '@/app/WidthSwitch';
import { Url } from '@/app/Url';
import { BiPlus } from "react-icons/bi";
import { useAutoSave } from '@/hooks/useAutoSave';
import { DummyForm } from '@/page/DummyForm';
import { DummyReserveForm } from '@/page/DummyReserveForm';


import { useSetRecoilState, useRecoilState } from 'recoil'
import { saveDataAtom, addonsAtom, productAtom, saveStatusAtom } from '@/providers/Atoms';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";

// import { EditMenu } from '@/page/EditMenu';


export const Page = React.memo(() => {
  
  // console.log("ページレンダリング");
  
  const { productId } = useParams();
  const { slug } = useParams();
  
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  // const setSaveData = useSetRecoilState(saveDataAtom)
  const setAddons = useSetRecoilState(addonsAtom)
  const [ product, setProduct ] = useRecoilState(productAtom)
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // const [ product, setProduct ] = useState([]);
  const [ setting, setSetting ] = useState([]);
  const [ page, setPage ] = useState([]);
  const pageState = { 
    page, setPage
  };
  
  const [ headerSections, setHeaderSections ] = useState([]);
  const [ mainSections, setMainSections ] = useState([]);
  const [ footerSections, setFooterSections ] = useState([]);

  const [ isLoading, setIsLoading ] = useState(true);
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    client.get(`/api/v1/products/${productId}/pages/${slug}`)
    .then(resp => {
      setProduct(resp.data.product);
      setSetting(resp.data.setting);
      setAddons(resp.data.addons);
      setPage(resp.data.page);
      setMainSections(resp.data.main_sections);
      setHeaderSections(resp.data.header_sections);
      setFooterSections(resp.data.footer_sections);
      // setSaveData(resp.data.save_data);
      LoadingStop();
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  

  
  const LoadingStop = () => {
    window.setTimeout(() =>{
      setIsLoading(false);
    }, 1500);
  };
  
  
  const InputChange = event => {
    const { name, value } = event.target;
    const newPage = { ...page, [name]: value };
    setPage(newPage);

    // setSaveData((prev)=> (
    //   {...prev, page: newPage}
    // ));
  };


  // 保存
  const updatePage = () => {
    client.patch(`/api/v1/pages/${page.id}`, page)
    .then(resp => {
      if(slug !== resp.data.slug ){
        navigate(`/product/${productId}/${resp.data.slug}/edit`);
        // window.location.reload();
      }
      setSaveStatus(false);
      console.log("保存完了");
      // setToastObj({message: "保存しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // オートセーブ
  useAutoSave(page, updatePage, 3000);
  
  
  // const Ref = useRef(null);
  
  // // 自動保存
  // useEffect(() => {
  //   if (Ref.current === null) {
  //     setTimeout(() =>{
  //       Ref.current = true;
  //     }, 3000);
  //     return;
  //   }
    
  //   if (Ref.current) {
  //     clearTimeout(Ref.current); 
  //     console.log("ページ自動保存待ち");
  //   }
    
  //   Ref.current = setTimeout(updatePage, 3000);
  // }, [page]);
  

  // クリア
  const Clear = (column) => {
    const newPage = {...page, [column]: null};
    setPage(newPage);

    // setSaveData((prev)=> (
    //   {...prev, page: newPage}
    // ));
  }
  
  const [ active, setActive ] = useState(false);
  const OpenToggle = ()=> {
    setActive((prev)=> !prev);
  };
  
  const [ open, setOpen ] = useState(false);
  const OpenHandler = ()=> {
    setOpen(true);
  };
  
  const [ pathOpen, setPathOpen ] = useState(false);
  const [ display, setDisplay ] = useState("編集");
  const PathToggle = ()=> {
    setPathOpen((prev)=> !prev);
    setDisplay(pathOpen ? "編集" : "閉じる");
  };
  
  const SettingCSS = css`
   font-family: ${setting.font};
   background: ${setting.back_color};
   
   p, a {
    font-size: ${setting.font_size && `${setting.font_size}px`};
   }
   
    @media screen and (max-width: 500px) {
      p, a {
        font-size: ${setting.font_size_s && `${setting.font_size_s}px`};
      }
    }
  `

  return (
    <>
      { isLoading && <Loading/> }
      
      {/*<EditMenu/>*/}
      
      <Layout product={product} pageState={pageState} InputChange={InputChange} Clear={Clear}>
      
        <PageNav toppage={page.toppage}/>
      
        <div className="wrapper mb_3">
          <div className="main_layer"></div>
          
          { slug !== "contact" 
            ?
              <div className="path_box">
                <div className="path_badge">
                  <span>
                    パス：{page.slug}
                    <button className="btn path_edit_btn" onClick={PathToggle}>{display}</button>
                  </span>
                </div>
                {pathOpen &&
                  <SlugEdit product={product} page={page} setPage={setPage} PathToggle={PathToggle}/> 
                }
              </div>
            : <h2 className="H2 color_white">お問い合わせページの編集</h2>
          }
          
          <div className="page_title">
            {page.toppage && <div className="badge badge_dark">トップ</div>}
            ページタイトル
          </div>
          
          <TextareaAutosize 
            className="input input_lg"
            type="text"
            name="title" 
            value={page.title || ''} 
            onChange={InputChange} 
            placeholder="ページタイトルを入力..."
          />
          <p className="explain color_white">※このタイトルが「検索エンジン」に表示されます。</p>
          
          <center>
            <Url slug={page.slug}>
              <button className="btn_edit" style={{width: "200px"}}>
                公開ページを見る<BiChevronRight/>
              </button>
            </Url>
          </center>
          
        </div>
        
        <div className="page_edit_wrapper">
          
          <HeaderSections 
            sections={headerSections} 
            setSections={setHeaderSections}
            SettingCSS={SettingCSS}
          />
          
          { mainSections.length !== 0 
            ? 
              <>
                <div className="wrapper">
                  <div className="headline_field">
                    <h2 className="page_label">メイン</h2>
                    {/*
                    <button 
                      className="btn_text"
                      style={{fontWeight: "bolder"}}
                      onClick={OpenToggle}
                    >
                      { !active ? "ヘッダー・フッター表示" : "閉じる" }
                    </button>
                    */}
                  </div>
                </div>
                <div className="page_area" css={SettingCSS}>
                  <Sections 
                    page_id={page.id} 
                    sections={mainSections} 
                    setSections={setMainSections}
                    sharing={null}
                    />
                </div>
              </>
            :
              <div className="wrapper">
                <h2 className="page_label">メイン</h2>
                <div className="step_guide" style={{borderRadius: "0 15px 15px 15px"}}>
                  <div className="step">ステップ３</div>
                  <p>ページタイトルを入力し、下記の<span className="color_key">「ブロックの追加」</span>からお好きなパーツ素材を選択してください。</p>
                  <p>保存は自動保存されます。</p>
                </div>
              </div>
          }
          
          <div className="wrapper mb_4">
            <button className="btn_add" onClick={OpenHandler}>
              <span><BiPlus/>ブロックを追加</span>
            </button>
            { open &&
              <NewSection 
                page_id={page.id} 
                sections={mainSections} 
                setSections={setMainSections}
                sharing={null}
                index={Object.keys(mainSections).length}
                key={page.id}
                setOpen={setOpen}
              />
            }
          </div>
          
          { slug == "contact" &&
            <DummyForm/>
          }
          { slug == "reserve" &&
            <DummyReserveForm/>
          }
          
          <FooterSections 
            sections={footerSections} 
            setSections={setFooterSections}
            SettingCSS={SettingCSS}
          />
          
        </div>
            
        <LightSetting
          page={page}
          InputChange={InputChange}
          updatePage={updatePage}
          Clear={Clear}
        />
        
        <style>
          {`
            .navbar .icon{
              display: none;
            }
            .main {
              width: calc(100% - 50px);
            }
            .main_area {
              max-width: 100%;
              padding: 0;
            }
            .sidebar {
              width: 50px;
            }
            @media screen and (max-width: 820px) {
              body {
                padding-bottom: 50px;
              }
              .main {
                width: 100%;
              }
              .sidebar {
                width: 100%;
              }
              .footer {
                display: none;
              }
            }
          `}
        </style>
        <style>
          {`
            ${setting.add_style ||""}
            ${page.add_style ||""}
          `}
        </style>
        
      </Layout>
    </>
  );
});

  // <button onClick={notify}>Notify</button>
  
    // const notify = () => {
  //   console.log("お知らせ");
  //   toast.success("お知らせ", {
  //     // position: toast.POSITION.TOP_CENTER
  //     position: "bottom-center",
  //     hideProgressBar: true
  //   });
  // };
  // const notify = () => toast.success("Wow so easy!");
  
  
//   const obj = [
//   {
//     id: 1,
//     img: "/h.jpg",
//     sub_img: "/1.jpg",
//     color: "",
//     sub_color: "",
//     title: "ブラッシュオム",
//     subtitle: "ブラッシュオム",
//     content: "MCを務めるのは松本人志と千鳥（大悟、ノブ）。ゲストと円卓を囲み、それぞれの好きなお酒で乾杯し宴（うたげ）がスタート。番組特製の“酒瓶ルーレット”でトークの順番を決定する",
//   },
//   {
//     id: 2,
//     img: "/1.jpg",
//     sub_img: null,
//     color: "",
//     sub_color: "",
//     title: "BRUSH HOMME",
//     subtitle: "ブラッシュオム",
//     content: "MCを務めるのは松本人志と千鳥（大悟、ノブ）。ゲストと円卓を囲み、それぞれの好きなお酒で乾杯し宴（うたげ）がスタート。番組特製の“酒瓶ルーレット”でトークの順番を決定する",
//   },
//   {
//     id: 3,
//     img: "/b.png",
//     sub_img: "/h.jpg",
//     color: "",
//     sub_color: "",
//     title: "秘書サプリ",
//     subtitle: "ブラッシュオム",
//     content: "MCを務めるのは松本人志と千鳥（大悟、ノブ）。ゲストと円卓を囲み、それぞれの好きなお酒で乾杯し宴（うたげ）がスタート。番組特製の“酒瓶ルーレット”でトークの順番を決定する",
//   },
// ]

{/*<Sections sectionsState={sectionsState}/> */}

              {/*
              <center><h1>メイン部分</h1></center>
              <div 
                className="page_area"
                style={{maxWidth: width}}
              >
                <Menubar/>
                <Blocks blocksState={blocksState} />

                <center>
                  <NewBlock page_id={page.id} blocksState={blocksState} key={page.id}/>
                </center>
                
                <style>
                  {page.add_style}
                </style>
              </div>
              */}
              
              
// <button className="" type="submit" onClick={updatePage}>保存</button>
// <button onClick={deletePage}>削除</button>

// <Modal btn_name="詳細設定">
//   <PageSetting pageState={pageState}/>
// </Modal>