import React from 'react';

export const fontFamilys = [
  {name: "Noto Sans JP（初期）", value: ""},
  // {name: "Noto Sans JP（デフォルト）", value: "'Noto Sans JP', sans-serif"},
  {name: "Noto Serif JP", value: "'Noto Serif JP', serif"},
  {name: "Cormorant Garamond", value: "'Cormorant Garamond', serif"},
  {name: "游明朝", value: "'游明朝','Yu Mincho',YuMincho"},
  {name: "游ゴシック", value: "'游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN'"},
  {name: "メイリオ", value: "'メイリオ', 'Meiryo', 'ヒラギノ角ゴシック'"},
  {name: "Meiryo UI", value: "'Meiryo UI', 'ヒラギノ角ゴシック'"},
  {name: "system-ui", value: "system-ui, -apple-system"},
]


export const placeholder = {
  title: "はじめてのホームページ作成ならPAGE STOCK",
  subtitle: "No-Code Web Creation",
  content: "ページストックは専門知識がない方でも誰でも簡単に「WEBサイトが作成できる」ノーコードツール。集客につながるLP型のWEBサイト・ホームページ・イベントページ等が楽ラク作成可能。",
  img: "/cafe.jpg",
}