import React from 'react'
import { BiChevronDown, BiLinkAlt } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";


export const PartTypePreview = React.memo(({ item }) => {
  
  let partType = item?.type.split(" ") || [];
  
  const SelectType = () => {
    
    if(item == null) {
      return;
    }
    
    switch(partType[0]) {
      case "MainVisual_A":
        return (
          <div className={`mainvisual_1 ${partType[1]} part_preview`}>
            <div className="visual_body">
              <h1>PAGE STOCK</h1>
              <h2>Web Creation</h2>
              <p>No-code Web Creation</p>
            </div>
            
            <div className="visual_img">
              <img src="/cafe.jpg"/>
            </div>
          </div>
        );
        break;
      case "Footer_A":
        return (
          <div className={`footer_1 ${partType[1]} part_preview`}>
            <div className="footer_header">
              <div className="title">PAGE STOCK</div>
              <div className="subtitle">Web Creation</div>
            </div>
            
            <div className="footer_body">
              <a>About</a>
              <a>Menu</a>
              <a>Access</a>
            </div>
          </div>
        );
        break;
      case "Headline_A":
        return (
          <div className={`headline_1 ${partType[1]} part_preview`}>
            <div className="title">PAGE STOCK</div>
            <div className="subtitle">Web Creation</div>
          </div>
        );
        break;
      case "Nabvar_A":
        return (
          <div className={`navbar_1 ${partType[1]} part_preview`}>
            <div className="nav_title">PAGE STOCK</div>
            <div className="nav_inner">
              <a>About</a>
              <a>Menu</a>
              <a>Access</a>
            </div>
            <div className="nav_btn">BTN</div>
          </div>
        );
        break;
      case "Point_A":
        return (
          <div className={`point_1 ${partType[1]} part_preview`}>
            <div className="point_body">
              <div className="subtitle">Web Creation</div>
              <div className="title">PAGE STOCK</div>
              <p>No-code Web Creation</p>
            </div>
        
            <div className="point_img">
              <img src="/cafe.jpg"/>
            </div>
          </div>
        );
        break;
      case "Button_A":
        return (
          <div className={`button_1 ${partType[1]} part_preview`}>
            <div className="subtitle">Web Creation</div>
            <button>BUTTON</button>
            <div className="microcopy">Content</div>
          </div>
        );
        break;
      case "Box_A":
        return (
          <div className={`box_1 ${partType[1]} part_preview`}>
            <div className="subtitle">Web Creation</div>
            <div className="title">PAGE STOCK</div>
            <p>No-code Web Creation Tool PAGE STOCK Services</p>
          </div>
        );
        break;
      case "Card_A":
        return (
          <div className={`card_1 ${partType[1]} part_preview`}>
            <div className="card_img">
              <img src="/cafe.jpg"/>
            </div>
            <div className="card_body">
              <div className="subtitle">Web Creation</div>
              <div className="title">PAGE STOCK</div>
              <p>No-code Web creation</p>
            </div>
          </div>
        );
        break;
      case "Price_A":
        return (
          <div className={`price_1 ${partType[1]} part_preview`}>
            <div className="price_header">
              <div className="title">Standard</div>
            </div>
            <div className="price_body">
              <div className="subtitle">￥15,000</div>
              <ul>
                <li>Content</li>
                <li>Content</li>
                <li>Content</li>
              </ul>
            </div>
          </div>
        );
        break;
      case "Profile_A":
        return (
          <div className={`profile_1 ${partType[1]} part_preview`}>
            <div className="profile_header">
              <div className="icon_layer">
                <div className="icon">
                  <img src="/cafe.jpg"/>
                </div>
              </div>
            </div>
            <div className="profile_body">
              <div className="title">NAME</div>
              <div className="subtitle">Web Designer</div>
              <p>Web creation</p>
            </div>
          </div>
        );
        break;
      case "Accordion_A":
        return (
          <div className={`accordion_1 ${partType[1]} part_preview`}>
            <div className="accordion_header">
              <div className="title">PAGE STOCK</div>
              <BiChevronDown/>
            </div>
            <div className="accordion_body">
              <p>No-code Web Creation Tool PAGE STOCK Services</p>
            </div>
          </div>
        );
        break;
      case "Comment_A":
        return (
          <div className={`comment_1 ${partType[1]} part_preview`}>
            <div className="comment_icon">
              <div className="icon">
                <img src="/cafe.jpg"/>
              </div>
              <div className="comment_name">Name</div>
            </div>
            <div className="comment_body">
              <p>No-code Web Creation Tool PAGE STOCK Services</p>
            </div>
          </div>
        );
        break;
      case "Rating_A":
        return (
          <div className={`rating_1 ${partType[1]} part_preview`}>
            <div className="rating_icon">
              <div className="icon">
                <img src="/cafe.jpg"/>
              </div>
              <div className="rating_name">Name</div>
            </div>
            <div className="rating_body">
              <div className="title">PAGE STOCK</div>
              <div className="stars_box">
                ★★★★★
                <div className="star_number">（5.0）</div>
              </div>
              <p>No-code web creation</p>
            </div>
          </div>
        );
        break;
      case "List_A":
        return (
          <div className={`list_1 ${partType[1]} part_preview`}>
            <div className="list_img">
              <img src="/cafe.jpg"/>
            </div>
            <div className="list_body">
              <div className="title">PAGE STOCK</div>
              <div className="subtitle">Web Designer</div>
              <ul>
                <li><FaCheck/>Content</li>
                <li><FaCheck/>Content</li>
                <li><FaCheck/>Content</li>
              </ul>
            </div>
          </div>
        );
        break;
      case "Item_A":
        return (
          <div className={`item_1 ${partType[1]} part_preview`}>
            <div className="subtitle">2023年</div>
            <div className="title">NEWS</div>
            <p>Web Creation</p>
          </div>
        );
        break;
      case "Step_A":
        return (
          <div className={`step_1 ${partType[1]} part_preview`}>
            <div className="subtitle">ステップ１</div>
            <div className="title">Web Creation</div>
            <p>No-code Web Creation Tool PAGE STOCK Services</p>
          </div>
        );
        break;
      case "Text_A":
        return (
          <div className={`text_1 ${partType[1]} part_preview`}>
            <p>No-code Web Creation Tool PAGE STOCK Services 
            No-code Web Creation Tool PAGE STOCK Services</p>
          </div>
        );
        break;
      case "Notice_A":
        return (
          <div className={`notice_1 ${partType[1]} part_preview`}>
            お知らせ
          </div>
        );
        break;
      case "Link_A":
        return (
          <div className={`link_1 ${partType[1]} part_preview`}>
            <BiLinkAlt/><a>Text Link</a>
          </div>
        );
        break;
      case "Image_A":
        return (
          <div className={`image_1 ${partType[1]} part_preview`}>
            <div className="image_img">
              <img src="/cafe.jpg"/>
            </div>
            
            <div className="text_layer">
              <div className="subtitle">PAGE STOCK</div>
              <div className="title">No-code Web Creation Tool</div>
            </div>
            <p>No-code Web Creation Tool</p>
          </div>
        );
        break;
      case "Navbtn_A":
        return (
          <div className={`navbtn_1 ${partType[1]} part_preview`}>
            BUTTON
          </div>
        );
        break;
      default:
        return <>{React.createElement(item.svg || "span")}</>
    };
  };

  return (
    <>
      {SelectType()}
    </>
  );
});