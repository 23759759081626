import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';


export const Profile_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("レビューパーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  const profileCSS = css`
    ${outlineCSS(part)}
    
    .icon {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .icon_layer {
      border-radius: ${img_0.radius && `${img_0.radius}px`};
    }
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
  `

  return (
    <>
      <div 
        className={`profile_1 ${part?.class_name} ${part?.add_class || ""}`}
        css={profileCSS}
        id={part.add_id}
      >
        <div className="profile_header">
          <div className="icon_layer">
            <div className="icon">
              <PartImg
                obj={img_0}
                setObj={setImg_0}
              />
            </div>
          </div>
        </div>
        
        <div className="profile_body">
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder="名前を入力..."
              clickHandler={titleHandler}
            />
          </h3>
          
          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder="肩書き、職業、キャッチコピーなど..."
              clickHandler={subtitleHandler}
            />
          </div>

          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              placeholder={placeholder.content}
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link &&
            <a className="part_btn">MORE</a>
          }
          
        </div>
      </div>
    </>
  );
  
});

// export const Profile_A = React.memo(({ part, InputChange, setOpen }) => {
  
//   // console.log("レビューパーツレンダ");
  
//   const profileCSS = css`
//     ${outlineCSS(part)}
//     .title {
//       ${fontCSS(part)}
//     }
//     .subtitle, p {
//       color: ${part.font_color};
//     }
//   `

//   return (
//     <>
//       <div 
//         className={`profile_1 ${part?.class_name} ${part?.add_class || ""}`}
//         css={profileCSS}
//         id={part.add_id}
//       >
//         <div className="profile_header">
//           <div className="icon_layer" onClick={()=>setOpen("Img")}>
//             <div className="icon">
//               <img src={part.img || placeholder.img} alt={part.alt}/>
//             </div>
//           </div>
//         </div>
        
//         <div className="profile_body">
//           <h3 className="title">
//             <input
//               type="text" 
//               name="title" 
//               value={part.title || ''} 
//               onChange={InputChange} 
//               placeholder="名前を入力..."
//             />
//           </h3>
          
//           <div className="subtitle">
//             <TextareaAutosize
//               type="text" 
//               name="subtitle" 
//               value={part.subtitle || ''} 
//               onChange={InputChange} 
//               placeholder="肩書き、職業、キャッチコピーなど..."
//             />
//           </div>

//           <p>
//             <TextareaAutosize
//               type="text"
//               minRows={2}
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.content}
//             />
//           </p>
          
//           {part.link &&
//             <a className="part_btn">MORE</a>
//           }
          
//         </div>
//       </div>
//     </>
//   );
  
// });