import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@/app/App';
import { StateProvider } from '@/providers/StateProviders';
import { ToastProvider } from '@/providers/ToastProvider';
import { ScrollToTop } from '@/app/ScrollToTop';
// import { KeyboardHandler } from '@/app/KeyboardHandler';
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; //Google認証
import { RecoilRoot } from "recoil";


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ScrollToTop/>
    <StateProvider>
      <ToastProvider>
        <RecoilRoot>
          <App/>
        </RecoilRoot>
      </ToastProvider>
    </StateProvider>
  </BrowserRouter>
);

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//   <BrowserRouter>
//     <ScrollToTop />
//     <StateProvider>
//       <ToastProvider>
//         <App {...container.dataset} />
//       </ToastProvider>
//     </StateProvider>
//   </BrowserRouter>
// );


