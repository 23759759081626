import React, { useState } from "react"
// import { PartChange } from '@/part/PartChange';


export const NoPart = React.memo(({ setOpen }) => {
  
  // const { open, setOpen } = openState;
  // const { part, setPart } = partState;
  // const [ open, setOpen ] = useState(true);
  
  return (
    <>
      <center>
        <button className="btn_dark" onClick={()=>setOpen("Type")}>パーツタイプを選択</button>
      </center>
    </>
  );
  
});