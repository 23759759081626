import { atom, selector } from 'recoil';


export const saveDataAtom = atom({
  key: 'saveDataAtom',
  default: []
});


export const clickPartAtom = atom({
  key: 'clickPartAtom',
  default: 0
});

export const clickBlockAtom = atom({
  key: 'clickBlockAtom',
  default: 0
});

export const clickSectionAtom = atom({
  key: 'clickSectionAtom',
  default: 0
});

export const imagesAtom = atom({
  key: 'imagesAtom',
  default: []
});

export const productIDAtom = atom({
  key: 'productIDAtom',
  default: ""
});

export const freeImagesAtom = atom({
  key: 'freeImagesAtom',
  default: []
});

export const productAtom = atom({
  key: 'productAtom',
  default: []
});

export const addonsAtom = atom({
  key: 'addonsAtom',
  default: []
});

export const optionsAtom = atom({
  key: 'optionsAtom',
  default: []
});

export const textDataAtom = atom({
  key: 'textDataAtom',
  default: []
});

export const textOpenAtom = atom({
  key: 'textOpenAtom',
  default: false
});


export const saveStatusAtom = atom({
  key: 'saveStatusAtom',
  default: false
});

export const limitPagesAtom = atom({
  key: 'limitPagesAtom',
  default: 1
});


// export const textStateAtom = atom({
//   key: 'textStateAtom',
//   default: []
// });


// const allDataSelector = selector({
//   key: 'allDataSelector',
//   get: ({ get }) => {
//     const data = get(saveDataAtom);
//     const newSaveData = {...data, setDataAtom}
//     return newSaveData;
//   },
// });