import React, { useState } from "react"
// import { useInView } from 'react-intersection-observer';
// import { ImgVideo } from '@/app/ImgVideo';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';

// import { useSetRecoilState } from 'recoil'
// import { textStateAtom } from '@/providers/Atoms';


export const MainVisual_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("メインビジュアルパーツ", "レンダ");
  
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers

  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  
  const headerCSS = css`
    ${outlineCSS(part)}
    
    .visual_img {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    h1 {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    h2 {
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
  `
  
  return (
    <>
      <div 
        className={`mainvisual_1 ${part.class_name} ${part.add_class || ""}`}
        css={headerCSS}
        id={part.add_id}
      >
        <div className="visual_body">
          <h1>
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h1>
          
          <h2>
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={placeholder.subtitle}
              clickHandler={subtitleHandler}
            />
          </h2>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              // placeholder={placeholder.content}
              placeholder="内容を入力..."
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link &&
            <a className="part_btn">MORE</a>
          }
        </div>
        
        <div className="visual_img">
          <PartImg
            obj={img_0}
            setObj={setImg_0}
          />
        </div>
          
      </div>
    </>
  );
});
