import React, { useRef, useState, useEffect } from "react"
import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { BiChevronDown } from "react-icons/bi";
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


export const Accordion_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("アコーディオンレンダ");
  const { title, setTitle, content, setContent } = textStates;
  const { titleHandler, contentHandler } = textHandlers
  
  
  const ref = useRef(null);
  const [ Active, setActive ] = useState(false);
  const [ Height, setHeight] = useState("0");
  
  const HeightSet = () => {
    const RefHeight = ref.current?.clientHeight; // 対象要素の高さの取得
    setHeight(`${RefHeight}px`); // 対象要素の高さの代入
  }
  
  useEffect(() => {
    // setHeight("auto");
    HeightSet();
  }, [content.content]);
  
  
  const HandleToggle = () => {
    if (ref.current) {
      HeightSet();
      setActive(!Active); // 表示切替
    }
  };
  
  const accordionCSS = css`
    ${outlineCSS(part)}

    .accordion_header .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    p {
      ${textCSS(content)}
    }
  `

  return (
    <>
      <div
        className={`accordion_1 ${part.class_name} ${part.add_class||""}`}
        css={accordionCSS}
        id={part.add_id}
      >
        <div className={`accordion_header ${Active ? "isOpen" : ""}`} onClick={HandleToggle}>
          <div className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </div>
          <span className={Active ? "isOpen" : ""}><BiChevronDown/></span>
        </div>
        
        <div
          className="accordion_body"
          style={{
            height: Active ? `${Height}` : "0",
            opacity: Active ? 1 : 0,
          }}
        >
          <p ref={ref}>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              placeholder={placeholder.content}
              clickHandler={contentHandler}
            />
          </p>
        </div>

      </div>
    </>
  );
  
});


// export const Accordion_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("アコーディオンレンダ");
  
//   const ref = useRef(null);
//   const [ Active, setActive ] = useState(false);
//   const [ Height, setHeight] = useState("0");
  
//   const HeightSet = () => {
//     const RefHeight = ref.current?.clientHeight; // 対象要素の高さの取得
//     setHeight(`${RefHeight}px`); // 対象要素の高さの代入
//   }
  
//   useEffect(() => {
//     // setHeight("auto");
//     HeightSet();
//   }, [part.content]);
  
  
//   const HandleToggle = () => {
//     if (ref.current) {
//       HeightSet();
//       setActive(!Active); // 表示切替
//     }
//   };
  
//   const accordionCSS = css`
//     ${outlineCSS(part)}
//     .accordion_header {
//       color: ${part.font_color};
//     }
//     .title {
//       ${fontCSS(part)}
//     }
//   `

//   return (
//     <>
//       <div
//         className={`accordion_1 ${part.class_name} ${part.add_class||""}`}
//         css={accordionCSS}
//         id={part.add_id}
//       >
//         <div className={`accordion_header ${Active ? "isOpen" : ""}`} onClick={HandleToggle}>
//           <div className="title">
//             <TextareaAutosize
//               type="text" 
//               name="title" 
//               value={part.title ||''} 
//               onChange={InputChange} 
//               placeholder={placeholder.title}
//             />
//           </div>
//           <span className={Active ? "isOpen" : ""}><BiChevronDown/></span>
//         </div>
        
//         <div
//           className="accordion_body"
//           style={{
//             height: Active ? `${Height}` : "0",
//             opacity: Active ? 1 : 0,
//           }}
//         >
//           <p ref={ref}>
//             <TextareaAutosize 
//               type="text"
//               minRows={3}
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.content}
//             />
//           </p>
//         </div>

//       </div>
//     </>
//   );
  
// });