import React from 'react'

export const DummyReserveForm = () => {
  
  let date = new Date();

  return (
    <section id="c_section">
      <div className="wrapper">
        <div className="contact_form" id="c_form">
          <h2 className="title">予約フォーム</h2>
    
          <div className="form_field">
            <label>お名前</label>
            <input 
              id="name"
              className="input"
              type="text"
              placeholder="お名前を入力..."
            />
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>メールアドレス（返信用）</label>
              <input 
                id="email"
                className="input"
                type="text"
                placeholder="例）info@example.com"
              />
            </div>
            
            <div className="field_2">
              <label>電話番号</label> 
              <input 
                id="tel"
                className="input"
                type="text"
                placeholder="例）090-1234-5678"
              />
            </div>
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>第１希望の日時</label> 
              <input 
                id="datetime"
                className="input"
                type="datetime-local"
                // step="600"
              />
            </div>
            
            <div className="field_2">
              <label>第２希望の日時</label> 
              <input 
                id="datetime_2"
                className="input"
                type="datetime-local"
              />
            </div>
            
          </div>
          
          <label>ご希望のプラン</label>
          <div className="selectbox">
            <select
              id="plan"
              className="" 
              name="plan"
            >
              <option value="">プランを選択してください。</option>
              {["プラン①", "プラン②"].map((item, index) => {
                return (
                  <option value={item} key={index}>{item}</option>
                );
              })}
            </select>
          </div>
          
          <div className="form_field">
            <label>メッセージ内容</label>
            <textarea 
              rows="6" 
              className="input"
              placeholder="メッセージ内容を入力..."
            />
          </div>
          
          <center>
            <p className="mb_2">
              ご予約内容を確認し、折り返しご連絡させていただきます。<br/>
              自動返信メールが届かない場合はご入力のメールアドレスが間違っている可能性がありますのでご注意ください。
            </p>
          </center>
          
          <button type="submit" className="form_btn">送信する</button>

        </div>
      </div>
    </section>
  );

};
