import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import imageCompression from 'browser-image-compression';

import { useRecoilState } from 'recoil'
import { imagesAtom, productAtom } from '@/providers/Atoms';


export const Upload = React.memo(() => {
  
  // console.log("画像アップロードボタン");
  
  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, s3key } = useContext(StateContext);
  // const { images, setImages } = useContext(ImagesContext);
  const [ images, setImages ] = useRecoilState(imagesAtom)
  const [ product, setProduct ] = useRecoilState(productAtom)
  
  
  // アクセスキー設定
  const s3Client = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
      accessKeyId: s3key.accesskey,
      secretAccessKey: s3key.secretkey,
    },
  });


  // 画像をDBに保存（railに送信）
  const AddImage = (save_date) => {
    client.post('/api/v1/images', save_date)
    .then(resp => {
      setImages([resp.data, ...images]);
      setToastObj({message: "アップロード完了", color: "#D1E7DD"});
      console.log("画像をアップロードしました");
    })
    .catch(e => {
      setToastObj({message: "アップロードに失敗しました", color: "#ffedab"});
      console.log(e)
    })
  };
  
  
  // s3に画像アップ
  const InputFileUp = (file) => {
    
    const params = {
      ACL: 'public-read',
      Bucket: s3key.bucket,
      Key: `image/product/${productId}/files/${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    
    const save_date = {
      img: `https://${params.Bucket}.s3.ap-northeast-1.amazonaws.com/${params.Key}`,
      img_type: "user",
      size: file.size,
      name: file.name,
      img_key: params.Key,
      subdomain: productId,
    };
    
    s3Client.send(new PutObjectCommand(params))
    .then(resp => {
      AddImage(save_date);
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 画像サイズの合計
  let totalSize = images.reduce(function(sum, obj){
    return sum + obj.size;
  }, 0);
  
  // 画像の合計容量の判定
  const totalSizeCheck = (fileSize) => {
    
    let total_size = totalSize + fileSize
    let total_size_MB = Math.round(total_size/1024/1024*10) /10;
    console.log("合計容量", total_size_MB);
    
    if (!product.plan && total_size_MB > 3) {
      setToastObj({message: "フリープランでは合計容量「3MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "ライトプラン" && total_size_MB > 7) {
      setToastObj({message: "ライトプランでは合計容量「7MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "スタンダードプラン" && total_size_MB > 15) {
      setToastObj({message: "スタンダードプランでは合計容量「15MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "ビジネスプラン" && total_size_MB > 30) {
      setToastObj({message: "ビジネスプランでは合計容量「30MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "制作代行プラン" && total_size_MB > 30) {
      setToastObj({message: "制作代行プランでは合計容量「30MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (total_size_MB > 50) {
      setToastObj({message: "合計容量「50MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else {
      return true;
    }
  }
  
  // 画像取得＆プレビュー
  const InputFile = async (e) => {
    const file = e.target.files[0];
    
    try {
      // 動画判定
      if(/.mp4|.MP4|.ogv|.webm/.test(file.name)) {
        console.log("動画アップ", file.name);
        
        if (product.plan == "スタンダードプラン") {
          if (file.size/1024/1024 > 5) {
            setToastObj({message: "スタンダードプランでは動画サイズ「5MB」以上はアップロードできません。", color: "#ffedab"});
            return; 
          } else {
            const judg = await totalSizeCheck(file.size); 
            if(judg) {
              InputFileUp(file);
            }
            return;
          }
        } else if (product.plan == "ビジネスプラン" || product.plan == "制作代行プラン") {
          if (file.size/1024/1024 > 10) {
            setToastObj({message: "動画サイズ「10MB」以上はアップロードできません。", color: "#ffedab"});
            return; 
          } else {
            const judg = await totalSizeCheck(file.size); 
            if(judg) {
              InputFileUp(file);
            }
            return;
          }
        } else {
          setToastObj({message: "フリープラン／ライトプランでは動画のアップロードができません。", color: "#ffedab"});
          return;
        };
      }

      if (file.size/1024 > 800) {
        console.log("画像リサイズ");
        // リサイズ内容
        const options = await {
          maxSizeMB: 0.8,
          maxWidthOrHeight: 3000,
          useWebWorker: true,
        }
        // リサイズ処置
        const ResizeFile = await imageCompression(file, options);
        // 合計容量の判定
        const judg = await totalSizeCheck(ResizeFile.size); 
        if(judg) {
          InputFileUp(ResizeFile);
        }
      } else {
        console.log("画像リサイズなし");
        // 合計容量の判定
        const judg = await totalSizeCheck(file.size); 
        if(judg) {
          InputFileUp(file);
        }
      }
      
    } catch (e) {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.error(e);
    }
    
  };
  
  
  return (
    <>
      {!product.plan &&
        <label>フリープランの最大合計容量：3.0MBまで</label>
      }
      
      <label className="upload_btn">
        画像をアップロード
        <input 
          type="file" 
          name="img" 
          // accept="image/*, video/*"
          onChange={InputFile}
        />
      </label>
    </>
  );
});
