import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';

import { Nav } from '@/product/Nav';
import { NewMenu } from '@/menu/NewMenu';
import { Menu } from '@/menu/Menu';
import { Subject } from '@/product/Subject';

import { useSetRecoilState } from 'recoil'
import { productAtom } from '@/providers/Atoms';


export const Menus = () => {
  
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  const { productId } = useParams();
  
  // const [ product, setProduct ] = useRecoilState(productAtom)
  const setProduct = useSetRecoilState(productAtom);
  const [ menus, setMenus ] = useState([]);
  const menusState = {
    menus, setMenus
  }
  
  // 画面遷移のため
  const navigate = useNavigate();

  useEffect(() => {
    client.get(`/api/v1/menuslist?subdomain=${productId}`)
    .then(resp => {
      setProduct(resp.data.product);
      setMenus(resp.data.menus);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  // console.log("メニューズ", menus);
  
  
  return (
    <>
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>

      <h1 className="H1 color_white pb_1">メニュー</h1>
      <div className="color_white mb_1">
        各パーツで使用するメニューの「作成／編集」。
        作成したメニューを、「ナビバー、フッター、メニューリスト等」のパーツで表示できます。
      </div>
      
      <NewMenu menusState={menusState}/>
      
      {menus.map((menu, index) => {
        return (
          <Menu menu_obj={menu} menusState={menusState} index={index} key={index}/>
        );
      })}
    </>
  );
};

// {menus.length !== 0 &&
//         <h2 className="H2">メニューリスト</h2>
//       }
      
