import React, { useState, useEffect, useRef } from "react"

import { Headline_A } from '@/part/type/Headline_A';
// import { Headline_B } from '@/part/type/Headline_B';
import { Box_A } from '@/part/type/Box_A';
import { Card_A } from '@/part/type/Card_A';
import { Point_A } from '@/part/type/Point_A';
import { Image_A } from '@/part/type/Image_A';
import { Accordion_A } from '@/part/type/Accordion_A';
// import { Contact_A } from '@/part/type/Contact_A';
import { Button_A } from '@/part/type/Button_A';
import { Nabvar_A } from '@/part/type/Nabvar_A';
// import { Nabvar_B } from '@/part/type/Nabvar_B';
import { Navbtn_A } from '@/part/type/Navbtn_A';
import { MainVisual_A } from '@/part/type/MainVisual_A';
// import { MainVisual_B } from '@/part/type/MainVisual_B';
import { List_A } from '@/part/type/List_A';
import { Footer_A } from '@/part/type/Footer_A';
import { Text_A } from '@/part/type/Text_A';
import { Link_A } from '@/part/type/Link_A';
import { Comment_A } from '@/part/type/Comment_A';
import { Price_A } from '@/part/type/Price_A';
import { Profile_A } from '@/part/type/Profile_A';
import { Rating_A } from '@/part/type/Rating_A';
import { Notice_A } from '@/part/type/Notice_A';
import { Item_A } from '@/part/type/Item_A';
import { Step_A } from '@/part/type/Step_A';
import { Html_A } from '@/part/type/Html_A';
import { HtmlTable_A } from '@/part/type/HtmlTable_A';
import { Sns_A } from '@/part/type/Sns_A';
import { Sitemap_A } from '@/part/type/Sitemap_A';
import { NoPart } from '@/part/type/NoPart';

import { TextSetting } from '@/text/TextSetting';
// import { ImgSetting } from '@/image/ImgSetting';


export const PartComponents = React.memo(({partState, InputChange, texts, 
  imgs, openState, Clear}) => {
    
  
  const { part, setPart } = partState
  // const { imgs } = imgsState
  
  const { open, setOpen } = openState
  const [ textType, setTextType ] = useState("");
  
  
  // console.log("img", imgs);
  
  // 各テキスト
  // const [ title, setTitle ] = useState([]);
  // const [ subtitle, setSubtitle ] = useState([]);
  // const [ content, setContent ] = useState([]);
  
  // useEffect(() => {
  //   texts.forEach((obj) => { 
  //     if (obj.text_type === "title") {
  //       setTitle(obj);
  //     } else if (obj.text_type === "subtitle") {
  //       setSubtitle(obj);
  //     } else if (obj.text_type === "content") {
  //       setContent(obj);
  //     }
  //   });
  // }, []);
  
  const [ title, setTitle ] = useState(
    texts.find((obj) => obj.text_type === "title") || []
  );
  
  const [ subtitle, setSubtitle ] = useState(
    texts.find((obj) => obj.text_type === "subtitle") || []
  );
  
  const [ content, setContent ] = useState(
    texts.find((obj) => obj.text_type === "content") || []
  );
  
  const textStates = { title, setTitle, subtitle, setSubtitle, content, setContent }
   
  
  const titleHandler = ()=> {
    setTextType("title");
    setOpen("Text");
  };
  
  const subtitleHandler = ()=> {
    setTextType("subtitle");
    setOpen("Text");
  };
  
  const contentHandler = ()=> {
    setTextType("content");
    setOpen("Text");
  };
  
  const textHandlers = { titleHandler, subtitleHandler, contentHandler }
  
  // スマホ時の画像たち
  // const imgs_sp = imgs.filter((obj) => obj.img_type === "SP") || []
  

  const SelectPart = () => {
    
    if(part == null) {
      return;
    }

    switch(part.component_name) {
      case "MainVisual_A":
        return <MainVisual_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Nabvar_A":
        return <Nabvar_A part={part} InputChange={InputChange} openState={openState} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Headline_A":
        return <Headline_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Button_A":
        return <Button_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Footer_A":
        return <Footer_A part={part} InputChange={InputChange} openState={openState} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Point_A":
        return <Point_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
      case "Text_A":
        return <Text_A part={part} setPart={setPart} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Box_A":
        return <Box_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Card_A":
        return <Card_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Image_A":
        return <Image_A part={part} setOpen={setOpen}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Accordion_A":
        return <Accordion_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "List_A":
        return <List_A part={part} InputChange={InputChange} setOpen={setOpen} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Comment_A":
        return <Comment_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Price_A":
        return <Price_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Profile_A":
        return <Profile_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Rating_A":
        return <Rating_A part={part} setPart={setPart}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Notice_A":
        return <Notice_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Item_A":
        return <Item_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Step_A":
        return <Step_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "HtmlTable_A":
        return <HtmlTable_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Html_A":
        return <Html_A part={part} InputChange={InputChange} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Link_A":
        return <Link_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Sns_A":
        return <Sns_A part={part}/>;
        break;
      case "Navbtn_A":
        return <Navbtn_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break
      case "Sitemap_A":
        return <Sitemap_A part={part}/>;
        break;
      default:
        return <NoPart setOpen={setOpen}/>;
    };
  };


  return (
    <>
      {SelectPart()}
      
      { open == "Text" &&
        <TextSetting
          obj={
            textType == "title" && title ||
            textType == "subtitle" && subtitle ||
            textType == "content" && content
          }
          setObj={
            textType == "title" && setTitle ||
            textType == "subtitle" && setSubtitle ||
            textType == "content" && setContent
          }
          setOpen={setOpen}
        />
      }
    </>
  );
});


      // { open == "Img" &&
      //   <ImgSetting 
      //     obj={part}
      //     setObj={setPart}
      //     InputChange={InputChange}
      //     // update={updatePart}
      //     // SaveDataUpdate={SaveDataUpdate}
      //     Clear={Clear} 
      //     type="part"
      //     setOpen={setOpen}
      //   />
      // }
      

// const SelectPart = () => {
    
//     if(part == null) {
//       return;
//     }

//     switch(part.component_name) {
//       case "MainVisual_A":
//         return <MainVisual_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "MainVisual_B":
//         return <MainVisual_B part={part} InputChange={InputChange} setOpen={setOpen} 
//         textStates={textStates} titleHandler={titleHandler} 
//         subtitleHandler={subtitleHandler} contentHandler={contentHandler}/>;
//         break;
//       case "Nabvar_A":
//         return <Nabvar_A part={part} InputChange={InputChange} setOpen={setOpen} Clear={Clear}/>;
//         break;
//       case "Nabvar_B":
//         return <Nabvar_B part={part}　InputChange={InputChange} setOpen={setOpen} Clear={Clear}
//         textStates={textStates} titleHandler={titleHandler} 
//         subtitleHandler={subtitleHandler} contentHandler={contentHandler}/>;
//         break;
//       case "Headline_A":
//         return <Headline_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Button_A":
//         return <Button_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Navbtn_A":
//         return <Navbtn_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Footer_A":
//         return <Footer_A part={part} InputChange={InputChange} setOpen={setOpen} Clear={Clear}/>;
//         break;
//       case "Point_A":
//         return <Point_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Notice_A":
//         return <Notice_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Text_A":
//         return <Text_A part={part} setPart={setPart} InputChange={InputChange}/>;
//         break;
//       case "Box_A":
//         return <Box_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Card_A":
//         return <Card_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Image_A":
//         return <Image_A part={part} setPart={setPart} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Accordion_A":
//         return <Accordion_A part={part} InputChange={InputChange}/>;
//         break;
//       case "List_A":
//         return <List_A part={part} InputChange={InputChange} setOpen={setOpen} Clear={Clear}/>;
//         break;
//       case "Comment_A":
//         return <Comment_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Price_A":
//         return <Price_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Profile_A":
//         return <Profile_A part={part} InputChange={InputChange} setOpen={setOpen}/>;
//         break;
//       case "Rating_A":
//         return <Rating_A part={part} InputChange={InputChange} ratingChanged={ratingChanged} setOpen={setOpen}/>;
//         break;
//       case "HtmlTable_A":
//         return <HtmlTable_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Html_A":
//         return <Html_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Link_A":
//         return <Link_A partState={partState} InputChange={InputChange}/>;
//         break;
//       case "Sns_A":
//         return <Sns_A part={part} InputChange={InputChange}/>;
//         break;
//       case "Contact_A":
//         return <Contact_A part={part}/>;
//         break;
//       default:
//         return <NoPart setOpen={setOpen}/>;
//     };
//   };