import React, { useState } from "react"
// import { PartTypes } from '@/part/PartTypes';
import { PartCategory } from '@/part/PartTypes';
import { PartSelect } from '@/part/PartSelect';
import { BiX } from "react-icons/bi";


export const PartChange = React.memo(({ partState, updatePart, setOpen}) => {
  
  // console.log("タイプ変更", "レンダ");
  
  const { part, setPart } = partState;
  // const [ active, setActive ] = useState(false);
  const [ show, setShow ] = useState("Part");
  
  // const OpenHandler = ()=> {
  //   setActive(true);
  // };
  
  // const PartTypeChange = event => {
  //   const { name, value } = event.target;
  //   let type = value.split(" ")
  //   const newPart = { ...part, component_name: type[0], class_name: type[1] }
  //   setPart(newPart);
  //   SaveDataUpdate(newPart);
  //   // setOpen("");
  //   // setShow("Part");
  // };
  
  const PartTypeChange = (value) => {
    let type = value.split(" ")
    setPart((prev)=> ({ ...prev, component_name: type[0], class_name: type[1] }));
    
    // const newPart = { ...part, component_name: type[0], class_name: type[1] }
    // setPart(newPart);
    // SaveDataUpdate(newPart);
  };
  
  // //マウスホバーで選択 
  // const TypeChangeOnMouse = (value)  => {
  //   let type = value.split(" ")
  //   const newPart = { ...part, component_name: type[0], class_name: type[1] }
  //   setPart(newPart);
  //   SaveDataUpdate(newPart);
  // };
  
  
  const CloseHandler = ()=> {
    setOpen("");
  };
  
  // const ShowOpen = (type)=> {
  //   setShow(type);
  // };
  
  
  const componentName = part.component_name || ""
  
  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
      
        <div className="items_bar_top">
          <div className="sidebar_title">
            パーツタイプ変更
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box select_type" name={`part_${part.id}`}>
          {show == "Part" &&
            <>
              {PartCategory.map((category, index) => {
                return (
                  <button key={index}
                    className={`type_btn ${componentName.includes(category.type) && "active"}`}
                    onClick={()=>setShow(category.type)}
                  >
                    {category.name}
                  </button>
                );
              })}
            </>
          }
          
          {show !== "Part" &&
            <button className="clear_btn mb_2" onClick={()=>setShow("Part")}>戻る</button>
          }
          
          <PartSelect 
            obj={part}
            show ={show}
            PartTypeChange={PartTypeChange}
            // TypeChangeOnMouse={TypeChangeOnMouse}
            CloseHandler={CloseHandler}
          />
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updatePart}>保存</button>
        </div>
      </div>
    </>
  );
  
});

// <button className="" type="submit" onClick={updatePart}>変更する</button>


            // <form 
            //   className="items_box select_type"
            //   style={{height: "calc(100% - 170px)"}}
            //   name={`part_${part.id}`}
            // >
            //   {PartTypes.map((item, index) => {
            //     return (
            //       <label 
            //         className="item" 
            //         key={index}
            //         style={{
            //           border: item.type == `${part.component_name} ${part.class_name}` && "2px solid #00ff2c"
            //         }}
            //       >
            //         <img src={`/${item.img}`}/>
            //         <input
            //           type="radio"
            //           name="component_name"
            //           value={item.type}
            //           onChange={PartTypeChange}
            //           checked={item.type == `${part.component_name} ${part.class_name}`}
            //         />
            //         <div className="type_name">{item.name}</div>
            //       </label>
            //     );
            //   })}
            // </form>