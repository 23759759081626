import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { ImgSelect } from '@/page/ImgSelect';
import { AddonJudg } from '@/addon/AddonJudg';
import { BiCamera } from "react-icons/bi";

// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom } from '@/providers/Atoms';


export const PageSetting = React.memo(({ pageState, InputChange, Clear }) => {
  
  // console.log("ページ設定レンダ");
  
  const { productId } = useParams();
  
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)
  
  const { page, setPage } = pageState;

  // const InputChange = event => {
  //   const { name, value } = event.target;
  //   setPage({ ...page, [name]: value });
  // };
  
  // 保存
  const updatePage = () => {
    client.patch(`/api/v1/pages/${page.id}`, page)
    .then(resp => {
      setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // // 画面遷移のため
  const navigate = useNavigate();
  
  // 削除
  const deletePage = () => {
    const sure = window.confirm(`このページ(ID:${page.id})「${page.title}」を本当に削除しますか？`);
    if (sure) {
      client.delete(`/api/v1/pages/${page.id}`)
      .then(resp => {
        navigate(`/product/${productId}/dashboard`);
        setToastObj({message: "ページを削除しました", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // トップページの変更
  const ToppageChange = () => {
    const sure = window.confirm(`このページをトップページに変更しますか？`);
    if (sure) {
      client.post(`/api/v1/pages/toppage_change/${page.id}`, { subdomain: productId})
      .then(resp => {
        setPage(resp.data);
        // setSaveData((prev)=> ({...prev, page: resp.data}));
        setToastObj({message: "トップページに変更しました", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  const [ open, setOpen ] = useState(false);

  return (
    <>
      <div className="sidebar_title mt_3">ページの設定</div>
      
      <div className="form_field">
        {page.toppage == true
        ? <div className="badge badge_key">トップページ</div>
        : <>
            <label>トップページの変更</label>
            <button className="btn_line w_100" onClick={ToppageChange}>このページをトップにする</button>
          </>
        }
      </div>
      
      <label>ステータス</label>
      <form className="radio_box" id={`page_${page.id}`}>
        {["公開", "非公開"].map((item) => {
          return (
            <label key={item}
              className={item == page.status ? "is_on": ""} 
              // style={{border: item == page.status && "1px solid #00ff2c"}}
            >
              <input
                type="radio"
                id={item}
                name="status"
                value={item}
                onChange={InputChange}
                checked={item == page.status}
              />
              {item}
            </label>
          );
        })}
      </form>
      
      <label>インデックス</label>
      <div className="radio_box">
        <label 
          className={page.index == "true" || page.index == true ? "is_on": ""} 
        >
          <input
            type="radio"
            id="index"
            name="index"
            value={true}
            onChange={InputChange}
          />検索エンジンに登録
        </label>
        <label 
          className={page.index == "false" || page.index == false ? "is_on": ""} 
        >
          <input
            type="radio"
            id="index"
            name="index"
            value={false}
            onChange={InputChange}
          />登録しない
        </label>
      </div>
      
      <div className="form_field">
        <label>アイキャッチ画像</label>
        <div className="page_img" onClick={()=>setOpen(true)}>
          <img src={page.img || "/noimg.png"}/>
          <div className="up_btn"><BiCamera/></div>
        </div>
        
        { open &&
          <ImgSelect 
            obj={page}
            setObj={setPage}
            InputChange={InputChange} 
            update={updatePage}
            Clear={Clear} 
            setOpen={setOpen}
          />
        }
      </div>
      
      <label>概要説明
        <div className="btn_clear" type="button" onClick={()=>Clear("description")}>クリア</div>
      </label>
      <TextareaAutosize 
        className="input" 
        type="text"
        name="description" 
        value={page.description || ''} 
        onChange={InputChange} 
        placeholder="概要説明を入力..."
      />
      
      <AddonJudg terms={/ビジネス|制作代行/} boolean={true}>
        <label>スタイル（CSS）
          <div className="btn_clear" type="button" onClick={()=>Clear("add_style")}>クリア</div>
        </label>
        <TextareaAutosize
          className="input input_code"
          type="text"
          name="add_style" 
          value={page.add_style || ''} 
          onChange={InputChange} 
          placeholder="コードを入力..."
        />
      </AddonJudg>
      
      <AddonJudg terms={/パスワード機能/} boolean={true}>
        <div className="form_field">
          <label>パスワードの設定
            <div className="btn_clear" type="button" onClick={()=>Clear("pw")}>クリア</div>
          </label>
          <input
            className="input"
            type="text"
            name="pw" 
            value={page.pw || ''} 
            onChange={InputChange} 
            placeholder="半角英数字を入力..."
            // pattern="^[a-zA-Z0-9]+$"
          />
          <p>半角英数字のみ有効</p>
        </div>
      </AddonJudg>
      
      <label>ページの削除</label>
      <button className="btn_delete w_100" onClick={deletePage}>削除する</button>
    </>
  );
});


// <h2 className="">ページの詳細設定(モーダル)</h2>
// <button className="base_btn" type="submit" onClick={updatePage}>変更する</button>


// <form className="radio_box">
//   <label 
//     htmlFor={page.id}
//     className={page.index == "true" || page.index == true ? "is_on": ""} 
//   >
//     <input
//       type="checkbox"
//       id={page.id}
//       name="index"
//       value={page.index == "false" || page.index == false ? true : false}
//       onChange={InputChange}
//       checked={page.index == "true" || page.index == true}
//     />
//     {page.index == "true" || page.index == true
//       ? "検索エンジンに登録する"
//       : "登録しない"
//     }
//   </label>
// </form>