import React from 'react';
import { placeholder } from '@/app/DefaultData';
import { isMobile } from "react-device-detect";
// import { imgCSS } from '@/part/type/Style';


export const ImgVideo = ({ obj }) => {
  
  // const CSS = css`
  //   ${imgCSS(obj)}
  // `
  let src;
  if (isMobile && obj.img_s) {
    src = obj.img_s;
  } else {
    src = obj.img;
  }
  
  const imgJudg = /.jpg|.jpeg|.JPG|.JPEG|.png|.gif|.tiff|.tif|.webp|.svg/.test(src)
  const videoJudg = /.mp4|.MP4|.ogv|.webm/.test(src)

  if (imgJudg || !src) {
    return <img src={src || placeholder.img} className="image" alt={obj.alt || ""}/>;
  } else if (videoJudg) {
    return <video src={src} autoPlay loop muted playsInline ></video>;
  } else {
    return <div className="file_badge">{ obj.name || "ファイル"}</div>;
  }
  
}