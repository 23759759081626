import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { SectionSetting } from '@/section/SectionSetting';
import { Normal } from '@/section/type/Normal';
import { Flowing } from '@/section/type/Flowing';
import { Switch } from '@/section/type/Switch';
import { Slide } from '@/section/type/Slide';
import { BiChevronUp, BiChevronDown, BiTrash } from "react-icons/bi";

import { useRecoilValue } from 'recoil'
import { clickSectionAtom } from '@/providers/Atoms';


export const Section = React.memo(({ section_id, sectionsState, orderUp, orderDown, index }) => {
  
  // console.log("セクション", section_id, "レンダ");
  
  const { client } = useContext(StateContext);
  const clickSection = useRecoilValue(clickSectionAtom)

  const { sections, setSections } = sectionsState
  
  const [ section, setSection ] = useState([]);
  const sectionState = { 
    section, setSection
  };
  const [ blocks, setBlocks ] = useState([]);
  const blocksState = { 
    blocks, setBlocks
  };
  
  useEffect(() => {
    client.get(`/api/v1/sections/${section_id}`)
    .then(resp => {
      setSection(resp.data.section);
      setBlocks(resp.data.blocks);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  
  
  // セクション削除用のモーダル
  const [modal, setModal] = useState(false);
  
  const openModal = () => {
    setModal(true);
  };


  // セクション削除
  const deleteSection = () => {
    const sure = window.confirm(`このセクションを本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/sections/${section.id}`)
      .then(resp => {
        // const newSections = [...sections];
        // newSections.splice(index, 1);
        // setSections(newSections);
        setSections((prev) =>
          prev.filter((obj) => obj.id !== section.id)
        );
      })
      .catch(e => {
        console.log(e);
      });
    }
  };
  
  
  const selectSection = () => {
    
    const type = section?.section_type || ""
    
    switch(type) {
      case "":
        return <Normal section_id={section_id} blocksState={blocksState}/>;
        break;
      case "Slide":
        return <Slide section={section} blocksState={blocksState} type="loop" key={`Slide_${section.id}`}/>;
        break;
      case "Fade":
        return <Slide section={section} blocksState={blocksState} type="fade" key={`Fade_${section.id}`}/>;
        break;
      case "Flowing":
        return <Flowing section={section} blocksState={blocksState}/>;
        break;
      case "Switch":
        return <Switch section_id={section_id} blocksState={blocksState}/>;
        break;
      default:
        return <div>セクションがありません。</div>;
    };
  };
  
  // const [ active, setActive ] = useState(false);
  
  // const OpenHandler = ()=> {
  //   setActive(true);
  // };
  

  return (
    <>
      {selectSection()}
      
      {modal &&
      　<div className="modal modal_delete" onClick={()=>setModal(false)}>
          <div className="modal_window" onClick={(e) => e.stopPropagation()}>
            <div className="modal_content">
              {selectSection()}
            </div>
            <div className="btn_area">
              <button className="back_btn" onClick={()=>setModal(false)}>もどる</button>
              <button className="delete_btn" onClick={deleteSection}>削除する</button>
            </div>
          </div>
        </div>
      }
      
      <div className="order_btn">
        <button onClick={()=>orderUp(index)}><BiChevronUp/></button>
        <button onClick={()=>orderDown(index)}><BiChevronDown/></button>
        <button className="delete_section" onClick={openModal}><BiTrash/></button>
      </div>
      
      { clickSection == section_id &&
        <SectionSetting
          sectionState={sectionState}
          // sectionsState={sectionsState}
          blocksState={blocksState}
        />
      }
    </>
  );
});

// <button className="section_set_btn" onClick={OpenHandler}>スライド</button>

      // <SectionSetting 
      //   section={section}
      //   blocks={blocks}
      //   InputChange={InputChange}
      //   SaveSection={SaveSection}
      //   AddBlock={AddBlock}
      //   DeleteSection={DeleteSection}
      // />